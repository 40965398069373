import { uaaIntlIds } from './uaa.ids';

export const uaaLangEsMx = {
  [uaaIntlIds.signIn.welcomeMessage]: '¡Bienvenido a Raio!',
  [uaaIntlIds.signIn.signInInstruction]:
    'Utilice su cuenta de Google para acceder',
  [uaaIntlIds.signIn.button.label]: 'Entrar con Google',
  [uaaIntlIds.signIn.manRidingBikeAlt]:
    'Ilustración de una persona en bicicleta con una mochila en la espalda',
};
