import { Tag as ChakraTag } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TagProps } from './tag.types';

export default forwardRef(function Tag(
  props: TagProps,
  ref: LegacyRef<HTMLSpanElement>
) {
  return <ChakraTag fontSize="xs" {...props} ref={ref} />;
});
