import { Divider as ChakraDivider } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { DividerProps } from './divider.types';

export default forwardRef(function Divider(
  props: DividerProps,
  ref: LegacyRef<HTMLHRElement>
) {
  return <ChakraDivider {...props} ref={ref} />;
});
