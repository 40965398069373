import { journeysIntlIds } from './journeys.ids';

export const journeysLangEnUs = {
  [journeysIntlIds.seo.pages.availabilityList.title]:
    'Riders availability list',

  [journeysIntlIds.seo.pages.availabilityList.description]:
    'Web application for rider management and last mile deliveries',

  [journeysIntlIds.availabilityList.hub
    .title]: `Riders at: {hubs_length, plural,
    =0 {No hubs}
    one {{hub_alias}}
    other {<highlight>#</highlight> hubs}}`,

  [journeysIntlIds.journeyStatus.all]: 'All',
  [journeysIntlIds.journeyStatus.pending]: 'Pending',
  [journeysIntlIds.journeyStatus.available]: 'Available',
  [journeysIntlIds.journeyStatus.inRoute]: 'In Route',
  [journeysIntlIds.journeyStatus.paused]: 'Paused',
  [journeysIntlIds.journeyStatus.canceled]: 'Canceled',
  [journeysIntlIds.journeyStatus.offline]: 'Offline',

  [journeysIntlIds.availabilityTable.cellHeader.hub]: 'Hub',
  [journeysIntlIds.availabilityTable.cellHeader.riderId]: 'Rider ID',
  [journeysIntlIds.availabilityTable.cellHeader.riderName]: 'Name',
  [journeysIntlIds.availabilityTable.cellHeader.riderToken]: 'Code',
  [journeysIntlIds.availabilityTable.cellHeader.createdAt]: 'Created at',
  [journeysIntlIds.availabilityTable.cellHeader.journeyStatus]: 'Rider Status',
  [journeysIntlIds.availabilityTable.cellHeader.lastUpdate]: 'Last update',

  [journeysIntlIds.availabilityTable.cellBody.journeyStatus.endShift.label]:
    'End shift',

  [journeysIntlIds.forceRiderCheckOutModal.title]: 'Check-out: {rider_name}',
  [journeysIntlIds.forceRiderCheckOutModal.description]:
    'Do you wish to end {rider_name} shift?',
  [journeysIntlIds.forceRiderCheckOutModal.buttons.endShift.label]: 'End shift',
  [journeysIntlIds.forceRiderCheckOutModal.buttons.cancel.label]: 'Cancel',
  [journeysIntlIds.forceRiderCheckOutModal.toast.onSuccess.title.label]:
    '{rider_name} was checked-out',
  [journeysIntlIds.forceRiderCheckOutModal.toast.onSuccess.description.label]:
    'at {formatted_time}',
  [journeysIntlIds.forceRiderCheckOutModal.toast.onError.title.label]:
    'Fail to check-out {rider_name}',
  [journeysIntlIds.forceRiderCheckOutModal.toast.onError.description.label]:
    'Please try again',

  [journeysIntlIds.noJourneysFound
    .label]: `There are no Riders {journey_status, select,
    pending {Pending}
    available {Available}
    inRoute {In Route}
    paused {Paused}
    canceled {Canceled}
    offline {Offline}
    other {}
  }`,
};
