import { InputRightElement as ChakraInputRightElement } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { InputRightElementProps } from './input-right-element.types';

export default forwardRef(function Input(
  props: InputRightElementProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraInputRightElement {...props} ref={ref} />;
});
