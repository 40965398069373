import { ModalBody as ChakraModalBody } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { ModalBodyProps } from './modal-body.types';

export default forwardRef(function ModalBody(
  props: ModalBodyProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraModalBody {...props} ref={ref} />;
});
