import { ModalCloseButton as ChakraModalCloseButton } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { ModalCloseButtonProps } from './modal-close-button.types';

export default forwardRef(function ModalCloseButton(
  _props: ModalCloseButtonProps,
  ref: LegacyRef<HTMLButtonElement>
) {
  return <ChakraModalCloseButton ref={ref} />;
});
