import { Tabs as ChakraTabs } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TabsProps } from './tabs.types';

export default forwardRef(function Tabs(
  props: TabsProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraTabs {...props} ref={ref} />;
});
