import { ChakraProvider } from '@chakra-ui/react';
import { PropsWithChildren, ReactElement } from 'react';

import { uiTheme } from '../_theme';

export default function Provider({
  children,
}: PropsWithChildren): ReactElement {
  return <ChakraProvider theme={uiTheme}>{children}</ChakraProvider>;
}
