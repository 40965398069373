import { useLazyQuery } from '@common/bff';
import { AuthHandler } from '@common/uaa/utils/auth-handler';
import { useEffect } from 'react';

import {
  UseProfileHookResponse,
  UseProfileQuery,
} from './use-profile.hook.types';
import { GET_USER_INFO } from './use-profile.query';

export default function useProfile(): UseProfileHookResponse {
  const [getProfile, { data }] = useLazyQuery<UseProfileQuery>(GET_USER_INFO);

  useEffect(
    function onLoadProfile() {
      if (AuthHandler.isAuthenticated) {
        getProfile();
      }
    },
    [getProfile]
  );

  return data?.me ?? null;
}
