export const envs = {
  isDevelopment: process.env.NODE_ENV === 'development',
  isProduction: process.env.NODE_ENV === 'production',
  googleClientId: process.env.NEXT_PUBLIC_GOOGLE_CLIENT_ID ?? '',
  googleAuthHttpUrl: process.env.NEXT_PUBLIC_GOOGLE_AUTH_URL ?? '',
  graphqlHttpUrl: process.env.NEXT_PUBLIC_GRAPHQL_HTTP_URL ?? '',
  restApiUrl: process.env.NEXT_PUBLIC_REST_API_URL ?? '',
  graphqlWsUrl: process.env.NEXT_PUBLIC_GRAPHQL_WS_URL ?? '',
  ddApplicationId: process.env.NEXT_PUBLIC_DD_APPLICATION_ID ?? '',
  ddClientToken: process.env.NEXT_PUBLIC_DD_CLIENT_TOKEN ?? '',
  ddService: process.env.NEXT_PUBLIC_DD_SERVICE ?? '',
  ddSite: process.env.NEXT_PUBLIC_DD_SITE ?? '',
  ddEnv: process.env.NEXT_PUBLIC_DD_ENV ?? '',
  ddVersion: process.env.NEXT_PUBLIC_DD_VERSION ?? '',
};
