export const hubsIntlIds = {
  seo: {
    pages: {
      performanceList: {
        title: 'hubsIntlIds.seo.pages.performanceList.title',
        description: 'hubsIntlIds.seo.pages.performanceList.description',
      },
    },
  },

  performanceList: {
    hub: {
      title: 'hubsIntlIds.performanceList.hub.title',
    },
  },

  hubStatus: {
    all: 'hubsIntlIds.hubStatus.all',
    active: 'hubsIntlIds.hubStatus.active',
    inactive: 'hubsIntlIds.hubStatus.inactive',
  },

  performanceTable: {
    cellHeader: {
      hub: 'hubsIntlIds.performanceTable.cellHeader.hub',
      status: 'hubsIntlIds.performanceTable.cellHeader.status',
      address: 'hubsIntlIds.performanceTable.cellHeader.address',
      deliveriesInProgress:
        'hubsIntlIds.performanceTable.cellHeader.deliveriesInProgress',
      ridersConnected:
        'hubsIntlIds.performanceTable.cellHeader.ridersConnected',
    },

    cellBody: {
      deliveriesInProgress: {
        total: {
          label:
            'hubsIntlIds.performanceTable.cellBody.deliveriesInProgress.total.label',
        },
        totalAtHub: {
          label:
            'hubsIntlIds.performanceTable.cellBody.deliveriesInProgress.totalAtHub.label',
        },
        totalInRoute: {
          label:
            'hubsIntlIds.performanceTable.cellBody.deliveriesInProgress.totalInRoute.label',
        },
      },

      ridersConnected: {
        total: {
          label:
            'hubsIntlIds.performanceTable.cellBody.ridersConnected.total.label',
        },
        totalAtHub: {
          label:
            'hubsIntlIds.performanceTable.cellBody.ridersConnected.totalAtHub.label',
        },
        totalInRoute: {
          label:
            'hubsIntlIds.performanceTable.cellBody.ridersConnected.totalInRoute.label',
        },
      },
    },
  },

  details: {
    deliveries: {
      inProgress: {
        label: 'hubsIntlIds.details.deliveries.inProgress.label',
        status: {
          label: 'hubsIntlIds.details.deliveries.inProgress.status.label',
          tag: {
            alt: {
              label:
                'hubsIntlIds.details.deliveries.inProgress.status.tag.alt.label',
            },
          },
          onTime: {
            label:
              'hubsIntlIds.details.deliveries.inProgress.status.onTime.label',
          },
          alreadyLate: {
            label:
              'hubsIntlIds.details.deliveries.inProgress.status.alreadyLate.label',
          },
          inScandal: {
            label:
              'hubsIntlIds.details.deliveries.inProgress.status.inScandal.label',
          },
        },
      },
      seeAll: {
        label: 'hubsIntlIds.details.deliveries.seeAll.label',
      },
    },

    journeys: {
      connected: {
        label: 'hubsIntlIds.details.journeys.connected.label',
        atHub: {
          label: 'hubsIntlIds.details.journeys.connected.atHub.label',
        },
        inRoute: {
          label: 'hubsIntlIds.details.journeys.connected.inRoute.label',
        },
        status: {
          tag: {
            alt: {
              label:
                'hubsIntlIds.details.journeys.connected.status.tag.alt.label',
            },
          },
          available: {
            label:
              'hubsIntlIds.details.journeys.connected.status.available.label',
          },
          assigned: {
            label:
              'hubsIntlIds.details.journeys.connected.status.assigned.label',
          },
          paused: {
            label: 'hubsIntlIds.details.journeys.connected.status.paused.label',
          },
          inRoute: {
            label:
              'hubsIntlIds.details.journeys.connected.status.inRoute.label',
          },
          headingHub: {
            label:
              'hubsIntlIds.details.journeys.connected.status.headingHub.label',
          },
        },
      },
      seeAll: {
        label: 'hubsIntlIds.details.journeys.seeAll.label',
      },
    },
  },

  noHubsFound: {
    label: 'hubsIntlIds.noHubsFound.label',
  },
};
