import { Tbody as ChakraTableBody } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TableBodyProps } from './table-body.types';

export default forwardRef(function Tbody(
  props: TableBodyProps,
  ref: LegacyRef<HTMLTableSectionElement>
) {
  return <ChakraTableBody {...props} ref={ref} />;
});
