import { ModalOverlay as ChakraModalOverlay } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { ModalOverlayProps } from './modal-overlay.types';

export default forwardRef(function ModalOverlay(
  props: ModalOverlayProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraModalOverlay {...props} ref={ref} />;
});
