import { countdownLangEnUs } from '@common/core-components/countdown/intl';
import { hubsMultiSelectLangEnUs } from '@common/core-components/hubs-multi-select/intl';
import { paginationLangEnUs } from '@common/core-components/pagination/intl';
import { statusTagLangEnUs } from '@common/core-components/status-tag/intl/en-US';
import { dashboardLayoutLangEnUs } from '@common/dashboard-layout/intl';
import { deliveriesLangEnUs } from '@modules/deliveries/intl';
import { errorsLangEnUs } from '@modules/errors/intl';
import { hubsLangEnUs } from '@modules/hubs/intl';
import { journeysLangEnUs } from '@modules/journeys/intl';
import { ridersLangEnUs } from '@modules/riders/intl';
import { uaaLangEnUs } from '@modules/uaa/intl';

const enUsMessages = {
  ...dashboardLayoutLangEnUs,
  ...deliveriesLangEnUs,
  ...paginationLangEnUs,
  ...journeysLangEnUs,
  ...uaaLangEnUs,
  ...errorsLangEnUs,
  ...hubsMultiSelectLangEnUs,
  ...countdownLangEnUs,
  ...hubsLangEnUs,
  ...ridersLangEnUs,
  ...statusTagLangEnUs,
};

export default enUsMessages;
