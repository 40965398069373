export default function verifyIfEveryInArray(
  arr: Array<any | undefined | null> | undefined | null,
  key: string,
  value: any
): boolean {
  if (!arr) {
    return false;
  }

  return arr.every((obj) => {
    if (obj === null || obj === undefined) return true;
    return obj[key] === value;
  });
}
