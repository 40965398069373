export const errorsIntlIds = {
  seo: {
    pages: {
      notFound: {
        title: 'errorsIntlIds.seo.pages.notFound.title',
        description: 'errorsIntlIds.seo.pages.notFound.description',
      },
    },
  },

  pageNotFound: {
    title: 'errorsIntlIds.pageNotFound.title',
    description: 'errorsIntlIds.pageNotFound.description',
    button: {
      label: 'errorsIntlIds.pageNotFound.button.label',
    },
  },

  systemError: {
    title: 'errorsIntlIds.systemError.title',
    description: 'errorsIntlIds.systemError.description',
    items: {
      signOut: {
        label: 'errorsIntlIds.systemError.items.signOut.label',
      },
      reload: {
        label: 'errorsIntlIds.systemError.items.reload.label',
      },
    },
  },
};
