import { forwardRef, Spinner as ChakraSpinner } from '@chakra-ui/react';
import { LegacyRef } from 'react';

import { SpinnerProps } from './spinner.types';

export default forwardRef(function Spinner(
  props: SpinnerProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraSpinner {...props} ref={ref} />;
});
