import { onError } from '@apollo/client/link/error';
import { hermesLogger } from '@common/observability';

import { isExpiredTokenError } from './utils';

export const graphQLErrorsMiddleware = onError(({ graphQLErrors }) => {
  if (graphQLErrors && !isExpiredTokenError(graphQLErrors))
    graphQLErrors.forEach(
      ({ message, locations, path, cause, stack, name, extensions }) => {
        hermesLogger.error(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`,
          {
            cause,
            locations,
            message,
            name,
            path,
            stack,
            extensions,
          }
        );
      }
    );
});
