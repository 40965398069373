import { ModalContent as ChakraModalContent } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { ModalContentProps } from './modal-content.types';

export default forwardRef(function ModalContent(
  props: ModalContentProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraModalContent {...props} ref={ref} />;
});
