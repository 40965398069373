import { ApolloClient, InMemoryCache } from '@apollo/client';
import { envs } from '@config';

import { mainMiddleware } from './middlewares';

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: mainMiddleware,
  name: 'Bff client',
  version: '0-beta',
  connectToDevTools: envs.isDevelopment,
});

export default client;
