import { MenuItem as ChakraMenuItem } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { MenuItemProps } from './menu-item.types';

export default forwardRef(function MenuItem(
  props: MenuItemProps,
  ref: LegacyRef<HTMLButtonElement>
) {
  return <ChakraMenuItem {...props} ref={ref} />;
});
