export const deliveriesIntlIds = {
  toast: {
    actionDispatched: {
      title: 'deliveriesIntlIds.toast.actionDispatched.title',
      description: 'deliveriesIntlIds.toast.actionDispatched.description',
    },
  },

  list: {
    hub: {
      title: 'deliveriesIntlIds.list.hub.title',
    },
    controlTower: {
      title: 'deliveriesIntlIds.list.controlTower.title',
    },
    tab: {
      inProgress: 'deliveriesIntlIds.list.tab.inProgress',
      inManualAnalysis: 'deliveriesIntlIds.list.tab.inManualAnalysis',
    },
  },

  deliveryStatus: {
    delivered: 'deliveriesIntlIds.deliveryStatus.delivered',
    canceled: 'deliveriesIntlIds.deliveryStatus.canceled',
    processing: 'deliveriesIntlIds.deliveryStatus.processing',
    ready: 'deliveriesIntlIds.deliveryStatus.ready',
    inRoute: 'deliveriesIntlIds.deliveryStatus.inRoute',
    arrival: 'deliveriesIntlIds.deliveryStatus.arrival',
    inManualAnalysis: 'deliveriesIntlIds.deliveryStatus.inManualAnalysis',

    emphasis: {
      processing: 'deliveriesIntlIds.deliveryStatus.emphasis.processing',
      ready: 'deliveriesIntlIds.deliveryStatus.emphasis.ready',
      inRoute: 'deliveriesIntlIds.deliveryStatus.emphasis.inRoute',
      arrival: 'deliveriesIntlIds.deliveryStatus.emphasis.arrival',
    },
  },

  deliverySearch: {
    type: {
      orderId: 'deliveriesIntlIds.deliverySearch.type.orderId',
      riderId: 'deliveriesIntlIds.deliverySearch.type.riderId',
    },
    input: {
      placeholder: 'deliveriesIntlIds.deliverySearch.input.placeholder',
      label: 'deliveriesIntlIds.deliverySearch.input.label',
    },
    submitButton: {
      label: 'deliveriesIntlIds.deliverySearch.submitButton.label',
    },
  },

  deliveryTable: {
    cellHeader: {
      hub: 'deliveriesIntlIds.deliveryTable.cellHeader.hub',
      orderId: 'deliveriesIntlIds.deliveryTable.cellHeader.orderId',
      orderedAt: 'deliveriesIntlIds.deliveryTable.cellHeader.orderedAt',
      deliveryEta: 'deliveriesIntlIds.deliveryTable.cellHeader.deliveryEta',
      client: 'deliveriesIntlIds.deliveryTable.cellHeader.client',
      address: 'deliveriesIntlIds.deliveryTable.cellHeader.address',
      status: 'deliveriesIntlIds.deliveryTable.cellHeader.status',
      riderAssignment:
        'deliveriesIntlIds.deliveryTable.cellHeader.riderAssignment',
    },
  },

  details: {
    title: 'deliveriesIntlIds.details.title',

    breadcrumb: {
      deliveries: 'deliveriesIntlIds.details.breadcrumb.deliveries',
      deliveryDetails: 'deliveriesIntlIds.details.breadcrumb.deliveryDetails',
    },

    hub: 'deliveriesIntlIds.details.hub',
    deliveryETA: 'deliveriesIntlIds.details.deliveryETA',
    deliveryStatus: 'deliveriesIntlIds.details.deliveryStatus',

    changeStatus: {
      button: {
        label: 'deliveriesIntlIds.details.changeStatus.button.label',
      },
    },

    confirmDeliveryReceipt: {
      button: {
        label: 'deliveriesIntlIds.details.confirmDeliveryReceipt.button.label',
      },
    },

    order: {
      confirmedAt: {
        label: 'deliveriesIntlIds.details.order.confirmedAt.label',
      },
      id: {
        label: 'deliveriesIntlIds.details.order.id.label',
      },
      link: {
        label: 'deliveriesIntlIds.details.order.link.label',
      },
    },

    history: {
      box: {
        delivery: {
          label: 'deliveriesIntlIds.details.history.box.delivery.label',
          arrivedAt: {
            label:
              'deliveriesIntlIds.details.history.box.delivery.arrivedAt.label',
          },
          deliveredAt: {
            label:
              'deliveriesIntlIds.details.history.box.delivery.deliveredAt.label',
          },
          canceledAt: {
            label:
              'deliveriesIntlIds.details.history.box.delivery.canceledAt.label',
          },
        },
        return: {
          label: 'deliveriesIntlIds.details.history.box.return.label',
          tripPointCanceledAt: {
            label:
              'deliveriesIntlIds.details.history.box.return.tripPointCanceledAt.label',
          },
          tripPointCancelationReason: {
            label:
              'deliveriesIntlIds.details.history.box.return.tripPointCancelationReason.label',
            value:
              'deliveriesIntlIds.details.history.box.return.tripPointCancelationReason.value',
          },
          returnedAt: {
            label:
              'deliveriesIntlIds.details.history.box.return.returnedAt.label',
          },
          returnConfirmedBy: {
            label:
              'deliveriesIntlIds.details.history.box.return.returnConfirmedBy.label',
          },
          canceledAt: {
            label:
              'deliveriesIntlIds.details.history.box.return.canceledAt.label',
          },
        },
      },
    },

    customer: {
      address: {
        label: 'deliveriesIntlIds.details.customer.address.label',
      },
      name: {
        label: 'deliveriesIntlIds.details.customer.name.label',
      },
      notes: {
        label: 'deliveriesIntlIds.details.customer.notes.label',
      },
    },

    trip: {
      id: {
        label: 'deliveriesIntlIds.details.trip.label',
      },
      mode: {
        label: 'deliveriesIntlIds.details.trip.mode.label',
      },
      status: {
        manual: {
          label: 'deliveriesIntlIds.details.trip.manual.label',
        },
        automatic: {
          label: 'deliveriesIntlIds.details.trip.automatic.label',
        },
      },
      assignRider: {
        label: 'deliveriesIntlIds.details.trip.assignRider.label',
      },
      assignedRiders: {
        label: 'deliveriesIntlIds.details.trip.assignedRiders.label',
      },
      suggestedModality: {
        label: 'deliveriesIntlIds.details.trip.suggestedModality.label',
        tag: {
          bicycle: {
            label:
              'deliveriesIntlIds.details.trip.suggestedModality.tag.bicycle.label',
          },
          motorcycle: {
            label:
              'deliveriesIntlIds.details.trip.suggestedModality.tag.motorcycle.label',
          },
        },
      },
      requestExternalDispatch: {
        label: 'deliveriesIntlIds.details.trip.requestExternalDispatch.label',
      },
    },

    rider: {
      profile: {
        label: 'deliveriesIntlIds.details.rider.profile.label',
      },
      changeRider: {
        label: 'deliveriesIntlIds.details.rider.changeRider.label',
      },
      assignmentStatus: {
        assigned: {
          label:
            'deliveriesIntlIds.details.rider.assignmentStatus.assigned.label',
        },
        offering: {
          label:
            'deliveriesIntlIds.details.rider.assignmentStatus.offering.label',
        },
        fail: {
          label: 'deliveriesIntlIds.details.rider.assignmentStatus.fail.label',
        },
      },
    },
  },

  riderAssignment: {
    mode: {
      manualOrAutomatic:
        'deliveriesIntlIds.riderAssignment.mode.manualOrAutomatic',
      manualAndAutomatic:
        'deliveriesIntlIds.riderAssignment.mode.manualAndAutomatic',
    },
    status: {
      noRiderAssigned:
        'deliveriesIntlIds.riderAssignment.status.noRiderAssigned',
      findingRider: 'deliveriesIntlIds.riderAssignment.status.findingRider',
      riderAssigned: 'deliveriesIntlIds.riderAssignment.status.riderAssigned',
      ridersAssigned: 'deliveriesIntlIds.riderAssignment.status.ridersAssigned',
    },
  },

  changeDeliveryStatusModal: {
    title: 'deliveriesIntlIds.changeDeliveryStatusModal.title',
    description: 'deliveriesIntlIds.changeDeliveryStatusModal.description',
    buttons: {
      cancel: {
        label: 'deliveriesIntlIds.changeDeliveryStatusModal.cancel.label',
      },
      saveAndContinue: {
        label:
          'deliveriesIntlIds.changeDeliveryStatusModal.saveAndContinue.label',
      },
    },
    toast: {
      onSuccess: {
        label:
          'deliveriesIntlIds.changeDeliveryStatusModal.toast.onSuccess.label',
      },
      onError: {
        label:
          'deliveriesIntlIds.changeDeliveryStatusModal.toast.onError.label',
      },
    },
  },

  confirmDeliveryReceiptModal: {
    title: 'deliveriesIntlIds.confirmDeliveryReceiptModal.title',
    description: 'deliveriesIntlIds.confirmDeliveryReceiptModal.description',
    buttons: {
      cancel: {
        label: 'deliveriesIntlIds.confirmDeliveryReceiptModal.cancel.label',
      },
      saveAndContinue: {
        label:
          'deliveriesIntlIds.confirmDeliveryReceiptModal.saveAndContinue.label',
      },
    },
    toast: {
      onSuccess: {
        title:
          'deliveriesIntlIds.confirmDeliveryReceiptModal.toast.onSuccess.title',
        description:
          'deliveriesIntlIds.confirmDeliveryReceiptModal.toast.onSuccess.description',
      },
      onError: {
        title:
          'deliveriesIntlIds.confirmDeliveryReceiptModal.toast.onError.title',
      },
    },
  },

  changeOrAssignTripRiderModal: {
    title: 'deliveriesIntlIds.changeOrAssignTripRiderModal.title',
    selectRider: {
      placeholder:
        'deliveriesIntlIds.changeOrAssignTripRiderModal.selectRider.placeholder',
      options: {
        noRiders:
          'deliveriesIntlIds.changeOrAssignTripRiderModal.selectRider.options.noRiders',
      },
      riderModality: {
        label:
          'deliveriesIntlIds.changeOrAssignTripRiderModal.selectRider.riderModality.label',
      },
    },
    buttons: {
      cancel: {
        label: 'deliveriesIntlIds.changeOrAssignTripRiderModal.cancel.label',
      },
      sendOffer: {
        label: 'deliveriesIntlIds.changeOrAssignTripRiderModal.sendOffer.label',
      },
    },
  },

  requestExternalDeliveryDispatch: {
    title: 'deliveriesIntlIds.requestExternalDeliveryDispatch.title',
    description:
      'deliveriesIntlIds.requestExternalDeliveryDispatch.description',

    itemsSize: {
      small:
        'deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.small',
      medium:
        'deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.medium',
      large:
        'deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.large',
      xlarge:
        'deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.xlarge',
    },

    reasons: {
      selectReason:
        'deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.selectReason',
      totalAbsenceOfRiders:
        'deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.totalAbsenceOfRiders',
      largeOrder:
        'deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.largeOrder',
      rain: 'deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.rain',
      strike:
        'deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.strike',
      distance:
        'deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.distance',
    },

    buttons: {
      request: {
        label:
          'deliveriesIntlIds.requestExternalDeliveryDispatch.buttons.request.label',
      },
      cancel: {
        label:
          'deliveriesIntlIds.requestExternalDeliveryDispatch.buttons.cancel.label',
      },
    },
  },

  cancelExternalDispatch: {
    title: 'deliveriesIntlIds.cancelExternalDispatch.title',
    description: 'deliveriesIntlIds.cancelExternalDispatch.description',

    agent: {
      client: 'deliveriesIntlIds.cancelExternalDispatch.agent.client',
      rider: 'deliveriesIntlIds.cancelExternalDispatch.agent.rider',
      internal: 'deliveriesIntlIds.cancelExternalDispatch.agent.internal',
    },

    reasons: {
      selectReason:
        'deliveriesIntlIds.cancelExternalDispatch.reasons.selectReason',
      client: {
        cancelation:
          'deliveriesIntlIds.cancelExternalDispatch.reasons.client.cancelation',
        wrong_info:
          'deliveriesIntlIds.cancelExternalDispatch.reasons.client.wrong_info',
        fraud_no_receive:
          'deliveriesIntlIds.cancelExternalDispatch.reasons.client.fraud_no_receive',
        fraud_no_order:
          'deliveriesIntlIds.cancelExternalDispatch.reasons.client.fraud_no_order',
        no_receive:
          'deliveriesIntlIds.cancelExternalDispatch.reasons.client.no_receive',
      },
      rider: {
        fraud: 'deliveriesIntlIds.cancelExternalDispatch.reasons.rider.fraud',
        unexpected:
          'deliveriesIntlIds.cancelExternalDispatch.reasons.rider.unexpected',
        risk_area:
          'deliveriesIntlIds.cancelExternalDispatch.reasons.rider.risk_area',
        client_not_found:
          'deliveriesIntlIds.cancelExternalDispatch.reasons.rider.client_not_found',
        address_not_found:
          'deliveriesIntlIds.cancelExternalDispatch.reasons.rider.address_not_found',
      },
      internal: {
        available_rider:
          'deliveriesIntlIds.cancelExternalDispatch.reasons.internal.available_rider',
        wait_time_too_long:
          'deliveriesIntlIds.cancelExternalDispatch.reasons.internal.wait_time_too_long',
        wrong_request:
          'deliveriesIntlIds.cancelExternalDispatch.reasons.internal.wrong_request',
      },
    },

    buttons: {
      request_cancel: {
        label:
          'deliveriesIntlIds.cancelExternalDispatch.buttons.request_cancel.label',
      },
      cancel: {
        label: 'deliveriesIntlIds.cancelExternalDispatch.buttons.cancel.label',
      },
    },
  },

  externalDispatch: {
    details: {
      uberId: 'deliveriesIntlIds.externalDispatch.details.uberId',
      model: 'deliveriesIntlIds.externalDispatch.details.model',
      modality: 'deliveriesIntlIds.externalDispatch.details.modality',
      trip: 'deliveriesIntlIds.externalDispatch.details.trip',
      manual: 'deliveriesIntlIds.externalDispatch.details.manual',
      openUber: 'deliveriesIntlIds.externalDispatch.details.openUber',
      cancelUber: 'deliveriesIntlIds.externalDispatch.details.cancelUber',
      status: 'deliveriesIntlIds.externalDispatch.details.status',
    },
    toasts: {
      failed: {
        title: 'deliveriesIntlIds.externalDispatch.toasts.failed.title',
        description:
          'deliveriesIntlIds.externalDispatch.toasts.failed.description',
      },
      pending: {
        title: 'deliveriesIntlIds.externalDispatch.toasts.pending.title',
        description:
          'deliveriesIntlIds.externalDispatch.toasts.pending.description',
      },
      riderAssigned: {
        title: 'deliveriesIntlIds.externalDispatch.toasts.riderAssigned.title',
        description:
          'deliveriesIntlIds.externalDispatch.toasts.riderAssigned.description',
      },
      onTheWay: {
        title: 'deliveriesIntlIds.externalDispatch.toasts.onTheWay.title',
        description:
          'deliveriesIntlIds.externalDispatch.toasts.onTheWay.description',
      },
      returning: {
        title: 'deliveriesIntlIds.externalDispatch.toasts.returning.title',
        description:
          'deliveriesIntlIds.externalDispatch.toasts.returning.description',
      },
      canceled: {
        title: 'deliveriesIntlIds.externalDispatch.toasts.canceled.title',
        description:
          'deliveriesIntlIds.externalDispatch.toasts.canceled.description',
      },
      returned: {
        title: 'deliveriesIntlIds.externalDispatch.toasts.returned.title',
        description:
          'deliveriesIntlIds.externalDispatch.toasts.returned.description',
      },
    },
  },

  seo: {
    pages: {
      list: {
        title: 'deliveriesIntlIds.seo.pages.list.title',
        description: 'deliveriesIntlIds.seo.pages.list.description',
      },

      details: {
        title: 'deliveriesIntlIds.seo.pages.details.title',
        description: 'deliveriesIntlIds.seo.pages.details.description',
      },
    },
  },

  confirmationChangeTripModeModal: {
    title: 'deliveriesIntlIds.confirmationChangeTripModeModal.title',
    description:
      'deliveriesIntlIds.confirmationChangeTripModeModal.description',
    buttons: {
      cancel: {
        label: 'deliveriesIntlIds.confirmationChangeTripModeModal.cancel.label',
      },
      assignManually: {
        label:
          'deliveriesIntlIds.confirmationChangeTripModeModal.assignManually.label',
      },
    },
  },

  noDeliveriesFound: {
    label: 'deliveriesIntlIds.noDeliveriesFound.label',

    searchEmptyState: {
      orderId: {
        label:
          'deliveriesIntlIds.noDeliveriesFound.searchEmptyState.orderId.label',
      },
      riderId: {
        label:
          'deliveriesIntlIds.noDeliveriesFound.searchEmptyState.riderId.label',
      },
    },
  },
};
