import { ModalFooter as ChakraModalFooter } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { ModalFooterProps } from './modal-footer.types';

export default forwardRef(function ModalFooter(
  props: ModalFooterProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraModalFooter {...props} ref={ref} />;
});
