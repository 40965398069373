import { Text as ChakraText } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TextProps } from './text.types';

export default forwardRef(function Text(
  props: TextProps,
  ref: LegacyRef<HTMLParagraphElement>
) {
  return <ChakraText {...props} ref={ref} />;
});
