import { Input as ChakraInput } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { InputProps } from './input.types';

export default forwardRef(function Input(
  props: InputProps,
  ref: LegacyRef<HTMLInputElement>
) {
  return <ChakraInput {...props} ref={ref} />;
});
