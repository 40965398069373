import { hubsIntlIds } from './hubs.ids';

export const hubsLangEnUs = {
  [hubsIntlIds.seo.pages.performanceList.title]: 'Hubs list',

  [hubsIntlIds.seo.pages.performanceList.description]:
    'Web application for rider management and last mile deliveries',

  [hubsIntlIds.performanceList.hub.title]: `{hubs_length, plural,
    =0 {No Hubs}
    one {Hub: {hub_alias}}
    other {List of <highlight>#</highlight> hubs}}`,

  [hubsIntlIds.hubStatus.all]: 'All',
  [hubsIntlIds.hubStatus.active]: 'Active',
  [hubsIntlIds.hubStatus.inactive]: 'Inactive',

  [hubsIntlIds.performanceTable.cellHeader.hub]: 'Hub',
  [hubsIntlIds.performanceTable.cellHeader.status]: 'Status',
  [hubsIntlIds.performanceTable.cellHeader.address]: 'Address',
  [hubsIntlIds.performanceTable.cellHeader.deliveriesInProgress]:
    'Deliveries in Progress',
  [hubsIntlIds.performanceTable.cellHeader.ridersConnected]: 'Riders Connected',

  [hubsIntlIds.noHubsFound.label]: `There are no {hubs_status, select,
    true {Active Hubs}
    false {Inactive Hubs}
    other {Hubs yet}
  }`,

  [hubsIntlIds.performanceTable.cellBody.deliveriesInProgress.total.label]:
    '{total} Deliveries',
  [hubsIntlIds.performanceTable.cellBody.deliveriesInProgress.totalAtHub.label]:
    '{total_at_hub} at Hub',
  [hubsIntlIds.performanceTable.cellBody.deliveriesInProgress.totalInRoute
    .label]: '{total_in_route} in Route',

  [hubsIntlIds.performanceTable.cellBody.ridersConnected.total.label]:
    '{total} Riders',
  [hubsIntlIds.performanceTable.cellBody.ridersConnected.totalAtHub.label]:
    '{total_at_hub} at Hub',
  [hubsIntlIds.performanceTable.cellBody.ridersConnected.totalInRoute.label]:
    '{total_in_route} in Route',

  [hubsIntlIds.details.deliveries.inProgress.label]:
    '<highlight>{total}</highlight> Deliveries in Progress',
  [hubsIntlIds.details.deliveries.seeAll.label]: 'See all',
  [hubsIntlIds.details.deliveries.inProgress.status
    .label]: `<highlight>{total}</highlight> Deliveries {status, select,
    at_hub {at the Hub}
    in_route {in Route}
    other {}
  }`,
  [hubsIntlIds.details.deliveries.inProgress.status.tag.alt.label]:
    'Icon of a distance between two points',
  [hubsIntlIds.details.deliveries.inProgress.status.onTime.label]:
    'Deliveries on Time',
  [hubsIntlIds.details.deliveries.inProgress.status.alreadyLate.label]:
    'Deliveries already late',
  [hubsIntlIds.details.deliveries.inProgress.status.inScandal.label]:
    'Deliveries in Scandal',

  [hubsIntlIds.details.journeys.connected.label]:
    '<highlight>{total}</highlight> Riders connected',
  [hubsIntlIds.details.journeys.seeAll.label]: 'See all',
  [hubsIntlIds.details.journeys.connected.atHub.label]:
    '<highlight>{total}</highlight> Riders at the Hub',
  [hubsIntlIds.details.journeys.connected.inRoute.label]:
    '<highlight>{total}</highlight> Riders in Route',
  [hubsIntlIds.details.journeys.connected.status.tag.alt.label]:
    'Icon of a motorcycle helmet',
  [hubsIntlIds.details.journeys.connected.status.available.label]:
    'Riders Available',
  [hubsIntlIds.details.journeys.connected.status.assigned.label]:
    'Riders Assigned',
  [hubsIntlIds.details.journeys.connected.status.paused.label]: 'Riders Paused',
  [hubsIntlIds.details.journeys.connected.status.inRoute.label]:
    'Riders in Route',
  [hubsIntlIds.details.journeys.connected.status.headingHub.label]:
    'Riders heading Hub',
};
