import { RadioGroup as ChakraRadioGroup } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { RadioGroupProps } from './radio-group.types';

export default forwardRef(function RadioGroup(
  props: RadioGroupProps,
  ref: LegacyRef<HTMLInputElement>
) {
  return <ChakraRadioGroup {...props} ref={ref} />;
});
