import { Box as ChakraBox } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { BoxProps } from './box.types';

export default forwardRef(function Box(
  props: BoxProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraBox {...props} ref={ref} />;
});
