export default function notNullArrayValuesCount(
  arr: Array<any | null | undefined> | null | undefined,
  key?: string
): number {
  if (!arr) {
    return 0;
  }

  return arr.filter((obj) => {
    if (obj === null) return false;
    return key ? obj[key] : obj !== null;
  }).length;
}
