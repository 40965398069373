import { Avatar as ChakraAvatar } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { AvatarProps } from './avatar.types';

export default forwardRef(function Avatar(
  props: AvatarProps,
  ref: LegacyRef<HTMLSpanElement>
) {
  return <ChakraAvatar {...props} ref={ref} />;
});
