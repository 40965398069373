import { deliveriesIntlIds } from './deliveries.ids';

export const deliveriesLangEsMx = {
  [deliveriesIntlIds.toast.actionDispatched.title]: 'Acción en progreso',
  [deliveriesIntlIds.toast.actionDispatched.description]:
    'Espere unos momentos mientras completamos esta acción.',

  [deliveriesIntlIds.seo.pages.list.title]: 'Lista de Entregas',
  [deliveriesIntlIds.seo.pages.list.description]:
    'Aplicación web para la gestión de Riders y Entregas de última milla',

  [deliveriesIntlIds.seo.pages.details.title]: 'Entrega {delivery_id}',
  [deliveriesIntlIds.seo.pages.details.description]:
    'Aplicación web para la gestión de Riders y Entregas de última milla',

  [deliveriesIntlIds.list.hub.title]: `Entregas: {hubs_length, plural,
      =0 {Sin Hubs}
      one {{hub_alias}}
      other {<highlight>#</highlight> Hubs}}`,
  [deliveriesIntlIds.list.controlTower.title]: 'Entregas: Tiendas',
  [deliveriesIntlIds.list.tab.inProgress]: 'En Progreso ({total_count})',
  [deliveriesIntlIds.list.tab.inManualAnalysis]: 'En Análisis ({total_count})',

  [deliveriesIntlIds.deliveryStatus.delivered]: 'Entregado',
  [deliveriesIntlIds.deliveryStatus.canceled]: 'Cancelado',
  [deliveriesIntlIds.deliveryStatus.processing]: 'Procesando',
  [deliveriesIntlIds.deliveryStatus.ready]: 'Listo',
  [deliveriesIntlIds.deliveryStatus.inRoute]: 'En Camino',
  [deliveriesIntlIds.deliveryStatus.arrival]: 'En Punto de Entrega',
  [deliveriesIntlIds.deliveryStatus.inManualAnalysis]: 'En Análisis',

  [deliveriesIntlIds.deliveryStatus.emphasis.processing]: '<b>Procesando</b>',
  [deliveriesIntlIds.deliveryStatus.emphasis.ready]: '<b>Listo</b>',
  [deliveriesIntlIds.deliveryStatus.emphasis.inRoute]: '<b>En Camino</b>',
  [deliveriesIntlIds.deliveryStatus.emphasis.arrival]:
    '<b>En Punto de Entrega</b>',

  [deliveriesIntlIds.deliverySearch.type.orderId]: 'ID Pedido',
  [deliveriesIntlIds.deliverySearch.type.riderId]: 'ID Rider',

  [deliveriesIntlIds.deliverySearch.input.label]: 'Buscar una Entrega',
  [deliveriesIntlIds.deliverySearch.input.placeholder]: 'Buscar',
  [deliveriesIntlIds.deliverySearch.submitButton.label]: 'Buscar',

  [deliveriesIntlIds.deliveryTable.cellHeader.hub]: 'Hub',
  [deliveriesIntlIds.deliveryTable.cellHeader.orderId]: 'ID Pedido',
  [deliveriesIntlIds.deliveryTable.cellHeader.orderedAt]: 'Ordenado a las',
  [deliveriesIntlIds.deliveryTable.cellHeader.deliveryEta]: 'ETA Entrega',
  [deliveriesIntlIds.deliveryTable.cellHeader.client]: 'Cliente',
  [deliveriesIntlIds.deliveryTable.cellHeader.address]: 'Dirección',
  [deliveriesIntlIds.deliveryTable.cellHeader.status]: 'Status',
  [deliveriesIntlIds.deliveryTable.cellHeader.riderAssignment]:
    'Rider asignación',

  [deliveriesIntlIds.details.title]: 'Entrega {delivery_id}',
  [deliveriesIntlIds.details.breadcrumb.deliveries]: 'Entregas',
  [deliveriesIntlIds.details.breadcrumb.deliveryDetails]:
    'Detalles de la Entrega',
  [deliveriesIntlIds.details.hub]: 'Hub:',
  [deliveriesIntlIds.details.deliveryETA]: 'ETA Entrega:',
  [deliveriesIntlIds.details.deliveryStatus]: 'Status de Entrega:',

  [deliveriesIntlIds.details.changeStatus.button
    .label]: `Cambiar a {delivery_status, select,
      pending {Listo}
      pre_processed {Listo}
      processed {Listo}
      picking {Listo}
      ready {En Camino}
      on_the_way {En Punto de Entrega}
      arrived {Entregado}
      delivered {}
      canceled {}
      unknown {}
      other {}}`,

  [deliveriesIntlIds.details.confirmDeliveryReceipt.button.label]:
    'Confirma has recibido',

  [deliveriesIntlIds.details.order.confirmedAt.label]: 'Pedido confirmado',
  [deliveriesIntlIds.details.order.id.label]: 'ID Pedido',
  [deliveriesIntlIds.details.order.link.label]: 'Abre pedido',

  [deliveriesIntlIds.details.history.box.delivery.label]:
    'Historial de Entrega',
  [deliveriesIntlIds.details.history.box.delivery.arrivedAt.label]:
    'Rider llegó a la dirección',
  [deliveriesIntlIds.details.history.box.delivery.deliveredAt.label]:
    'Rider entregó el pedido',
  [deliveriesIntlIds.details.history.box.delivery.canceledAt.label]:
    'Pedido cancelado',

  [deliveriesIntlIds.details.history.box.return.label]:
    'Historial de Devoluciones',
  [deliveriesIntlIds.details.history.box.return.tripPointCanceledAt.label]:
    'Rider no pudo entregar',
  [deliveriesIntlIds.details.history.box.return.tripPointCancelationReason
    .label]: 'Razón',
  [deliveriesIntlIds.details.history.box.return.returnedAt.label]:
    'Pedido devuelto',
  [deliveriesIntlIds.details.history.box.return.returnConfirmedBy.label]:
    'Confirmado por',
  [deliveriesIntlIds.details.history.box.return.canceledAt.label]:
    'Pedido cancelado',
  [deliveriesIntlIds.details.history.box.return.tripPointCancelationReason
    .value]: `{cancelation_reason, select,
    customer_not_found {No encontré al cliente}
    refused_by_customer {Cliente rechazó la entrega}
    wrong_address {Dirección esta mal}
    other {}
  }`,

  [deliveriesIntlIds.details.customer.address.label]: 'Dirección',
  [deliveriesIntlIds.details.customer.name.label]: 'Nombre del cliente',
  [deliveriesIntlIds.details.customer.notes.label]: 'Observaciones del cliente',
  [deliveriesIntlIds.details.trip.id.label]: 'VIAJE: {trip_id}',
  [deliveriesIntlIds.details.trip.mode.label]:
    '{trip_mode, select, manual {Manual} automatic {Automática} other {}}',
  [deliveriesIntlIds.details.trip.assignedRiders.label]:
    'Riders Asignados ({riders_count})',
  [deliveriesIntlIds.details.trip.assignRider.label]: 'Asignar Rider',
  [deliveriesIntlIds.details.trip.requestExternalDispatch.label]:
    'Solicitar Uber',
  [deliveriesIntlIds.details.trip.suggestedModality.label]: 'Modal indicado:',
  [deliveriesIntlIds.details.trip.suggestedModality.tag.bicycle.label]:
    'Bicicleta',
  [deliveriesIntlIds.details.trip.suggestedModality.tag.motorcycle.label]:
    'Moto',
  [deliveriesIntlIds.details.rider.profile.label]: 'Ver el perfil',
  [deliveriesIntlIds.details.rider.changeRider.label]: 'Cambiar Rider',
  [deliveriesIntlIds.details.rider.assignmentStatus.assigned.label]: 'Asignado',
  [deliveriesIntlIds.details.rider.assignmentStatus.offering.label]:
    'Ofrecimiento',
  [deliveriesIntlIds.details.rider.assignmentStatus.fail.label]:
    'Error al enviar oferta',

  [deliveriesIntlIds.riderAssignment.mode
    .manualOrAutomatic]: `{riders_count, plural,
      =0 {{trip_mode, select, manual {Manual} automatic {Automática} other {}}}
      one {{trip_mode, select, manual {Manual} automatic {Automática} other {}}}
      other {# {trip_mode, select, manual {Manual} automatic {Automática} other {}}}}`,
  [deliveriesIntlIds.riderAssignment.mode.manualAndAutomatic]:
    '{manual_trips_count} Manual, {automatic_trips_count} Automática',
  [deliveriesIntlIds.riderAssignment.status.noRiderAssigned]:
    'Sin Rider asignado',
  [deliveriesIntlIds.riderAssignment.status.findingRider]: 'Ofreciendo',
  [deliveriesIntlIds.riderAssignment.status.riderAssigned]: '{rider_name}',
  [deliveriesIntlIds.riderAssignment.status.ridersAssigned]:
    '{riders_assigned_count}/{riders_count} Riders asignados',

  [deliveriesIntlIds.changeDeliveryStatusModal.title]:
    'Cambiar estado de Entrega?',
  [deliveriesIntlIds.changeDeliveryStatusModal.description]:
    '<b>Esta acción impacta la operación.</b> Le recomendamos revisar antes de guardar.',
  [deliveriesIntlIds.changeDeliveryStatusModal.buttons.cancel.label]:
    'Cancelar',
  [deliveriesIntlIds.changeDeliveryStatusModal.buttons.saveAndContinue.label]:
    'Guardar y continuar',
  [deliveriesIntlIds.changeDeliveryStatusModal.toast.onSuccess
    .label]: `El status de Entrega cambió a {delivery_status, select,
      pending {Listo}
      pre_processed {Listo}
      processed {Listo}
      picking {Listo}
      ready {En Camino}
      on_the_way {En Punto de Entrega}
      arrived {Entregado}
      delivered {}
      canceled {Cancelado}
      unknown {}
      other {}}`,
  [deliveriesIntlIds.changeDeliveryStatusModal.toast.onError.label]:
    'Error al cambiar el status de Entrega',

  [deliveriesIntlIds.confirmDeliveryReceiptModal.title]:
    '¿Alguien de la tienda recibió el pedido?',
  [deliveriesIntlIds.confirmDeliveryReceiptModal
    .description]: `Si confirma esta acción, no se puede deshacer.\n\n
    Es obligatorio esperar hasta que alguien de la tienda reciba y verifique los artículos del pedido del {action_author, select,
      external_dispatch {conductor de Uber}
      rider {Rider}
      other {}} antes de continuar.\n\n
    Una vez que confirmes la recepción, el pedido se cancelará automáticamente.`,
  [deliveriesIntlIds.confirmDeliveryReceiptModal.buttons.cancel.label]:
    'Cancelar',
  [deliveriesIntlIds.confirmDeliveryReceiptModal.buttons.saveAndContinue.label]:
    'Confirmar recepción',
  [deliveriesIntlIds.confirmDeliveryReceiptModal.toast.onSuccess
    .title]: `Hub recibió el pedido a través del {action_author, select,
      external_dispatch {conductor Uber}
      rider {Rider}
      other {}}`,
  [deliveriesIntlIds.confirmDeliveryReceiptModal.toast.onSuccess.description]:
    'Ahora solo espera a que el pedido se cancele automáticamente',
  [deliveriesIntlIds.confirmDeliveryReceiptModal.toast.onError.title]:
    'Error al confirmar recepción',

  [deliveriesIntlIds.changeOrAssignTripRiderModal.title]:
    'Nuevo Rider: Entrega {delivery_id}',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.selectRider.placeholder]:
    'Seleccionar Rider',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.selectRider.options.noRiders]:
    'Sin Rider',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.buttons.cancel.label]:
    'Cancelar',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.buttons.sendOffer.label]:
    'Enviar oferta',
  [deliveriesIntlIds.changeOrAssignTripRiderModal.selectRider.riderModality
    .label]: `{modality, select,
          bicycle {Bicicleta}
          motorcycle {Moto}
          other {}
        }`,

  [deliveriesIntlIds.requestExternalDeliveryDispatch.title]:
    '¿Necesitas un Uber para esta entrega?',
  [deliveriesIntlIds.requestExternalDeliveryDispatch
    .description]: `Si solicita que un repartidor de Uber realice esta entrega, solo se ofrecerá a los Usuarios manualmente.
    Acceda a BTLR para verificar la cantidad de artículos en el pedido y seleccionar el peso del pedido y el motivo del pedido.`,
  [deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.small]: '1-2 kg',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.medium]:
    '2-5 kg',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.large]:
    '5-10 kg',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.itemsSize.xlarge]:
    '10-20 kg',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.selectReason]:
    'Seleccione el motivo',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons
    .totalAbsenceOfRiders]: 'Ausencia total de Riders',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.largeOrder]:
    'Orden grande: Los Riders se niegan a aceptar la solicitud',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.rain]:
    'Lluvia: Los Riders se niegan a aceptar la solicitud',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.strike]:
    'Huelga: Los Riders se niegan a aceptar la solicitud',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.reasons.distance]:
    'Distancia: Los Riders se niegan a aceptar la solicitud',

  [deliveriesIntlIds.requestExternalDeliveryDispatch.buttons.request.label]:
    'Solicitar Uber',
  [deliveriesIntlIds.requestExternalDeliveryDispatch.buttons.cancel.label]:
    'Cancelar',
  [deliveriesIntlIds.cancelExternalDispatch.title]:
    '¿Necesitas cancelar la entrega a través de Uber?',
  [deliveriesIntlIds.cancelExternalDispatch
    .description]: `Saiba que se a entrega pelo Uber estiver em andamento, você precisará <b>aguardar até que a loja confirme que o entregador da Uber devolveu o pedido antes de cancelar o pedido</b>\n\nSe o pedido não for cancelado, a sua entrega só será ofertada para as pessoas Riders de forma manual.\n\n<b>Selecione quem solicitou e o motivo para cancelar</b>`,
  [deliveriesIntlIds.cancelExternalDispatch.agent.client]: 'Cliente',
  [deliveriesIntlIds.cancelExternalDispatch.agent.internal]: 'Tienda o CX',
  [deliveriesIntlIds.cancelExternalDispatch.agent.rider]: 'Conductor Uber',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.selectReason]:
    'Seleccione el motivo',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.cancelation]:
    'Cancelación: Cliente canceló el pedido',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.wrong_info]:
    'Compra o dirección incorrecta: Cliente canceló el pedido',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.fraud_no_receive]:
    'Sospecha de fraude: Cliente se negó a recibir',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.fraud_no_order]:
    'Sospecha de fraude: Cliente dice que no realizó la compra',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.client.no_receive]:
    'Cliente se negó a recibir',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.fraud]:
    'Sospecha de fraude: Conductor no recogió el pedido',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.unexpected]:
    'Imprevistos: Conductor no puede continuar con la entrega',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.risk_area]:
    'Área de riesgo: Conductor se negó a continuar',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.client_not_found]:
    'Conductor no pudo encontrar al cliente',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.rider.address_not_found]:
    'Conductor no pudo encontrar la dirección de entrega',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.internal.available_rider]:
    'Tienda: Hay Riders disponibles para entrega',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.internal
    .wait_time_too_long]: 'CX: La solicitud está tardando más de lo habitual',
  [deliveriesIntlIds.cancelExternalDispatch.reasons.internal.wrong_request]:
    'CX: Se suponía que la solicitud no debía realizarse',
  [deliveriesIntlIds.cancelExternalDispatch.buttons.request_cancel.label]:
    'Cancelar Uber',
  [deliveriesIntlIds.cancelExternalDispatch.buttons.cancel.label]: 'Cancelar',
  [deliveriesIntlIds.externalDispatch.details.uberId]:
    'ID Pedido Uber: {uberId}',
  [deliveriesIntlIds.externalDispatch.details.model]: 'Modelo:',
  [deliveriesIntlIds.externalDispatch.details.modality]: 'Modal:',
  [deliveriesIntlIds.externalDispatch.details.trip]: 'VIAJE:',
  [deliveriesIntlIds.externalDispatch.details.manual]: 'Manual:',
  [deliveriesIntlIds.externalDispatch.details.openUber]: 'Abrir en la Uber',
  [deliveriesIntlIds.externalDispatch.details.cancelUber]: 'Cancelar Uber',
  [deliveriesIntlIds.externalDispatch.details.status]: `{status, select,
    pending {Ofreciendo a los conductores Uber}
    returning {Uber regresando con pedido}
    returned {Uber regresó a el hub}
    other {Uber asignado}
  }`,

  [deliveriesIntlIds.externalDispatch.toasts.failed.title]:
    'Error al solicitar Uber Direct',
  [deliveriesIntlIds.externalDispatch.toasts.pending.title]:
    'Se solicita conductor de entrega Uber',
  [deliveriesIntlIds.externalDispatch.toasts.riderAssigned.title]:
    'Conductor de Uber asignado',
  [deliveriesIntlIds.externalDispatch.toasts.onTheWay.title]:
    'El conductor Uber comenzó a realizar la entrega',
  [deliveriesIntlIds.externalDispatch.toasts.returning.title]:
    'El conductor Uber regresa a el hub',
  [deliveriesIntlIds.externalDispatch.toasts.returned.title]:
    'Uber Direct cancelado',
  [deliveriesIntlIds.externalDispatch.toasts.canceled.title]:
    'Uber Direct cancelado',
  [deliveriesIntlIds.externalDispatch.toasts.failed.description]:
    'Algo salió mal. Inténtalo de nuevo',
  [deliveriesIntlIds.externalDispatch.toasts.pending.description]:
    'Espera a que te asigne uno',
  [deliveriesIntlIds.externalDispatch.toasts.riderAssigned.description]:
    'Sigue la ruta usando el link de seguimiento',
  [deliveriesIntlIds.externalDispatch.toasts.onTheWay.description]:
    'Sigue la ruta usando el link de seguimiento',
  [deliveriesIntlIds.externalDispatch.toasts.returning.description]:
    'Espera a que el conductor te devuelva el pedido',
  [deliveriesIntlIds.externalDispatch.toasts.returned.description]:
    'El conductor no pudo entregar',
  [deliveriesIntlIds.externalDispatch.toasts.canceled.description]:
    'Necesitas solicitar un nuevo conductor Uber nuevamente',

  [deliveriesIntlIds.noDeliveriesFound
    .label]: `No hay Entregas en {delivery_status, select,
        in_manual_analysis {En Análisis}
        delivered {Entregado}
        canceled {Cancelado}
        unknown {}
        other {Progreso}
      }`,
  [deliveriesIntlIds.noDeliveriesFound.searchEmptyState.orderId
    .label]: `Ups! No pudimos encontrar ningún <b>ID de Pedido</b> en {delivery_status, select,
            in_manual_analysis {En Análisis}
            delivered {Entregado}
            canceled {Cancelado}
            other {Progreso}} con <b>{search_value}</b>`,
  [deliveriesIntlIds.noDeliveriesFound.searchEmptyState.riderId
    .label]: `Ups! No pudimos encontrar ningún <b>ID de Rider</b> en Entregas en {delivery_status, select,
          in_manual_analysis {En Análisis}
          delivered {Entregado}
          canceled {Cancelado}
          other {Progreso}} con <b>{search_value}</b>`,
};
