import { BreadcrumbSeparator as ChakraBreadcrumbSeparator } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { BreadcrumbSeparatorProps } from './breadcrumb-separator.types';

export default forwardRef(function BreadcrumbSeparator(
  props: BreadcrumbSeparatorProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraBreadcrumbSeparator {...props} ref={ref} />;
});
