import { Table as ChakraTable } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TableProps } from './table.types';

export default forwardRef(function Table(
  props: TableProps,
  ref: LegacyRef<HTMLTableElement>
) {
  return <ChakraTable {...props} ref={ref} />;
});
