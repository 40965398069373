import { forwardRef } from '@chakra-ui/react';
import { Select as ChakraSelect } from 'chakra-react-select';

import { MultiSelectProps, MultiSelectRef } from './multi-select.types';

export default forwardRef(function MultiSelect(
  props: MultiSelectProps,
  ref: MultiSelectRef
) {
  return <ChakraSelect {...props} ref={ref} />;
});
