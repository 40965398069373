import { Breadcrumb as ChakraBreadcrumb } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { BreadcrumbProps } from './breadcrumb.types';

export default forwardRef(function Breadcrumb(
  props: BreadcrumbProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraBreadcrumb {...props} ref={ref} />;
});
