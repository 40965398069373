import { envs } from '@config';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { useRouter } from 'next/router';
import { PropsWithChildren, ReactElement, useEffect } from 'react';

import { AuthHandler } from '../utils/auth-handler';

export default function UaaProvider({
  children,
}: PropsWithChildren): ReactElement {
  const router = useRouter();

  const REDIRECT_PATH = '/sign-in';

  useEffect(function onLoadAndCheckIfSignedIn() {
    if (!AuthHandler.isAuthenticated && router?.pathname !== REDIRECT_PATH) {
      router?.replace(REDIRECT_PATH);
    }
    /**
     * TODO: Pensar em quais momentos vai rodar esse effect
     */
  }, []);

  return (
    <GoogleOAuthProvider
      clientId={envs.googleClientId}
      onScriptLoadSuccess={console.log}
      onScriptLoadError={console.log}
    >
      {children}
    </GoogleOAuthProvider>
  );
}
