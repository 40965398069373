import { TagLabel as ChakraTagLabel } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TagLabelProps } from './tag-label.types';

export default forwardRef(function TagLabel(
  props: TagLabelProps,
  ref: LegacyRef<HTMLSpanElement>
) {
  return <ChakraTagLabel {...props} ref={ref} />;
});
