export default function setTextColorByStatus(status: string) {
  switch (status) {
    case 'high':
      return {
        color: 'red.500',
      };
    case 'medium':
      return {
        color: 'yellow.500',
      };
    case 'normal':
      return {
        color: 'gray.600',
      };
    default:
      return {
        color: 'gray.600',
      };
  }
}
