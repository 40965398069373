import { hermesLogger } from '@common/observability';
import axios from 'axios';

import { envs } from '../../../config/envs';

export type AuthToken = {
  tokenType?: string;
  accessToken: string;
  refreshToken: string;
};

const AUTH_KEY = 'auth';

export class AuthHandler {
  static getTokens = (): { accessToken?: string; refreshToken?: string } => {
    const authStringify = global.localStorage.getItem(AUTH_KEY);

    const { accessToken, refreshToken }: Partial<AuthToken> = authStringify
      ? JSON.parse(authStringify)
      : {};

    return { accessToken, refreshToken };
  };

  static get authorization(): string {
    const { accessToken } = AuthHandler.getTokens();

    return `Bearer ${accessToken}`;
  }

  static get isAuthenticated(): boolean {
    const { accessToken } = AuthHandler.getTokens();

    return !!accessToken;
  }

  static get canRefresh(): boolean {
    const { accessToken, refreshToken } = AuthHandler.getTokens();

    return !!accessToken && !!refreshToken;
  }

  static setTokens = (accessToken: string, refreshToken?: string) => {
    global.localStorage.setItem(
      AUTH_KEY,
      JSON.stringify({ accessToken, refreshToken })
    );
  };

  static clearTokens = () => {
    global.localStorage.removeItem(AUTH_KEY);
  };

  static refreshTokens = async (): Promise<void> => {
    try {
      const { refreshToken: currentRefreshToken } = AuthHandler.getTokens();

      const {
        data: { accessToken, refreshToken },
      } = await axios.get<AuthToken>(`${envs.restApiUrl}/auth/refresh`, {
        headers: {
          'refresh-token': currentRefreshToken,
        },
      });

      if (!accessToken)
        throw new Error(`accessToken is not valid ${accessToken}`);

      AuthHandler.setTokens(accessToken, refreshToken ?? currentRefreshToken);
    } catch (error) {
      hermesLogger.error('[AuthHandler] Error refreshing token', {
        error: JSON.stringify(error),
      });
      window.location.replace('/sign-out');
    }
  };
}
