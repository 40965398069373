import { Thead as ChakraTableHead } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TableHeadProps } from './table-head.types';

export default forwardRef(function Thead(
  props: TableHeadProps,
  ref: LegacyRef<HTMLTableSectionElement>
) {
  return <ChakraTableHead {...props} ref={ref} />;
});
