export default function isEmpty(value?: any): boolean {
  if (value === null || value === undefined) {
    return true;
  }

  if (typeof value !== 'number' && value.length === 0) {
    return true;
  }

  if (typeof value === 'object' && Object.keys(value).length < 1) {
    return true;
  }

  return false;
}
