import { setContext } from '@apollo/client/link/context';
import { AuthHandler } from '@common/uaa/utils/auth-handler';

export const authMiddleware = setContext((_, { headers }) => {
  return {
    headers: {
      ...headers,
      authorization: AuthHandler.authorization,
    },
  };
});
