import { gql } from '@apollo/client';

export const GET_USER_INFO = gql`
  query UaaModule_GetUserInfo {
    me {
      email
      locale
      name
      pictureUrl
      accessType
      hubs {
        id
        alias
      }
      hubIds
    }
  }
`;
