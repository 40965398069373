import { ModalHeader as ChakraModalHeader } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { ModalHeaderProps } from './modal-header.types';

export default forwardRef(function ModalHeader(
  props: ModalHeaderProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraModalHeader {...props} ref={ref} />;
});
