import { Flex as ChakraFlex } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { FlexProps } from './flex.types';

export default forwardRef(function Flex(
  props: FlexProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraFlex {...props} ref={ref} />;
});
