import { Tag } from '@common/ui-components';
import { ReactElement } from 'react';

import type { IntlTagWrapperProps } from './create-intl-tag-wrapper.types';

export default function createIntlTagWrapper(
  chunks: IntlTagWrapperProps
): ReactElement {
  return (
    <Tag mx={2} py={2} size="lg">
      {chunks}
    </Tag>
  );
}
