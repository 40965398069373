import { Button as ChakraButton } from '@chakra-ui/react';
import { forwardRef, HTMLProps, LegacyRef } from 'react';

import { ButtonLinkProps } from './button-link.types';

export default forwardRef(function ButtonLink(
  props: ButtonLinkProps,
  ref: LegacyRef<HTMLButtonElement & HTMLProps<HTMLAnchorElement>>
) {
  return <ChakraButton {...props} as="a" ref={ref} />;
});
