import { journeysIntlIds } from './journeys.ids';

export const journeysLangPtBr = {
  [journeysIntlIds.seo.pages.availabilityList.title]:
    'Lista de disponibilidade dos Riders',

  [journeysIntlIds.seo.pages.availabilityList.description]:
    'Aplicação web para gerenciamento de Riders e Entregas de última milha',

  [journeysIntlIds.availabilityList.hub
    .title]: `Riders de: {hubs_length, plural,
      =0 {Sem hubs}
      one {{hub_alias}}
      other {<highlight>#</highlight> hubs}}`,

  [journeysIntlIds.journeyStatus.all]: 'Todos',
  [journeysIntlIds.journeyStatus.pending]: 'Pendente',
  [journeysIntlIds.journeyStatus.available]: 'Disponível',
  [journeysIntlIds.journeyStatus.inRoute]: 'Em Rota',
  [journeysIntlIds.journeyStatus.paused]: 'Em Pausa',
  [journeysIntlIds.journeyStatus.canceled]: 'Cancelada',
  [journeysIntlIds.journeyStatus.offline]: 'Offline',

  [journeysIntlIds.availabilityTable.cellHeader.hub]: 'Hub',
  [journeysIntlIds.availabilityTable.cellHeader.riderId]: 'ID Rider',
  [journeysIntlIds.availabilityTable.cellHeader.riderName]: 'Nome',
  [journeysIntlIds.availabilityTable.cellHeader.riderToken]: 'Código',
  [journeysIntlIds.availabilityTable.cellHeader.createdAt]: 'Criado em',
  [journeysIntlIds.availabilityTable.cellHeader.journeyStatus]:
    'Status do Rider',
  [journeysIntlIds.availabilityTable.cellHeader.lastUpdate]:
    'Última atualização',

  [journeysIntlIds.availabilityTable.cellBody.journeyStatus.endShift.label]:
    'Encerrar turno',

  [journeysIntlIds.forceRiderCheckOutModal.title]: 'Check-out: {rider_name}',
  [journeysIntlIds.forceRiderCheckOutModal.description]:
    'Deseja encerrar o turno de {rider_name}?',
  [journeysIntlIds.forceRiderCheckOutModal.buttons.endShift.label]:
    'Encerrar turno',
  [journeysIntlIds.forceRiderCheckOutModal.buttons.cancel.label]: 'Cancelar',
  [journeysIntlIds.forceRiderCheckOutModal.toast.onSuccess.title.label]:
    '{rider_name} fez check-out',
  [journeysIntlIds.forceRiderCheckOutModal.toast.onSuccess.description.label]:
    'às {formatted_time}',
  [journeysIntlIds.forceRiderCheckOutModal.toast.onError.title.label]:
    'Erro no check-out de {rider_name}',
  [journeysIntlIds.forceRiderCheckOutModal.toast.onError.description.label]:
    'Por favor, tente novamente',

  [journeysIntlIds.noJourneysFound
    .label]: `Não há Riders {journey_status, select,
      pending {Pendentes}
      available {Disponíveis}
      inRoute {Em Rota}
      paused {Em Pausa}
      canceled {Cancelados}
      offline {Offline}
      other {}
    }`,
};
