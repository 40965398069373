import { BffProvider } from '@common/bff';
import { I18nProvider } from '@common/i18n';
import { UaaProvider } from '@common/uaa';
import { UIProvider } from '@common/ui-components';
import { AppPropsWithLayout } from '@types';
import Head from 'next/head';

import { BootstrapMain } from '../main';

export default function BootstrapApp({
  Component,
  pageProps,
}: AppPropsWithLayout) {
  const getLayout = Component.getLayout ?? ((page) => page);

  return (
    <>
      <Head>
        <meta charSet="utf-8" />
        <meta httpEquiv="X-UA-Compatible" content="IE=edge" />
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,minimum-scale=1"
        />
        <meta name="theme-color" content="#1759FF" />

        <link rel="manifest" href="/manifest.json" />

        <link
          rel="shortcut icon"
          href="/icons/favicon.ico"
          type="image/x-icon"
        />
        <link
          href="/icons/icon-72x72.png"
          rel="icon"
          type="image/png"
          sizes="72x72"
        />
        <link
          href="/icons/icon-96x96.png"
          rel="icon"
          type="image/png"
          sizes="96x96"
        />

        <link rel="apple-touch-icon" href="/icons/icon-192x192.png"></link>
      </Head>

      <UaaProvider>
        <I18nProvider>
          <BffProvider>
            <UIProvider>
              {getLayout(
                <BootstrapMain>
                  <Component {...pageProps} />
                </BootstrapMain>
              )}
            </UIProvider>
          </BffProvider>
        </I18nProvider>
      </UaaProvider>
    </>
  );
}
