import { TableContainer as ChakraTableContainer } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TableContainerProps } from './table-container.types';

export default forwardRef(function TableContainer(
  props: TableContainerProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraTableContainer {...props} ref={ref} />;
});
