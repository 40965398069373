import { DrawerBody as ChakraDrawerBody } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { DrawerBodyProps } from './drawer-body.types';

export default forwardRef(function DrawerBody(
  props: DrawerBodyProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraDrawerBody {...props} ref={ref} />;
});
