import { MenuList as ChakraMenuList } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { MenuListProps } from './menu-list.types';

export default forwardRef(function MenuList(
  props: MenuListProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraMenuList {...props} ref={ref} />;
});
