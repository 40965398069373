import { Text } from '@common/ui-components';
import { ReactElement, ReactNode } from 'react';

export default function createHighlightTextWrapper(
  str: ReactNode
): ReactElement {
  return (
    <Text as="strong" color="gray.700">
      {str}
    </Text>
  );
}
