export const ridersIntlIds = {
  seo: {
    pages: {
      list: {
        title: 'ridersIntlIds.seo.pages.list.title',
        description: 'ridersIntlIds.seo.pages.list.description',
      },
    },
  },

  list: {
    title: 'ridersIntlIds.list.title',

    tab: {
      all: 'ridersIntlIds.list.tabs.all',
      active: 'ridersIntlIds.list.tabs.active',
      inactive: 'ridersIntlIds.list.tabs.inactive',
    },
  },

  search: {
    input: {
      type: 'ridersIntlIds.search.input.type',
      label: 'ridersIntlIds.search.input.label',
      placeholder: 'ridersIntlIds.search.input.placeholder',
    },
    submitButton: {
      label: 'ridersIntlIds.search.input.submitButton.label',
    },
  },

  table: {
    header: {
      id: 'ridersIntlIds.table.header.riderId',
      name: 'ridersIntlIds.table.header.fullName',
      email: 'ridersIntlIds.table.header.email',
      document: 'ridersIntlIds.table.header.document',
      modality: 'ridersIntlIds.table.header.modality',
      status: 'ridersIntlIds.table.header.status',
    },
  },

  rider: {
    modality: {
      label: 'ridersIntlIds.rider.modality.label',
    },

    status: {
      active: 'ridersIntlIds.rider.status.active',
      inactive: 'ridersIntlIds.rider.status.inactive',
    },
  },

  emptyState: {
    label: 'ridersIntlIds.emptyState.label',

    search: {
      label: 'ridersIntlIds.emptyState.search.label',
    },
  },
};
