import { FormatDateOptions } from '@common/i18n';

export default function formatDateBasedOnTimeZone(
  value: string | number | Date,
  options: FormatDateOptions
) {
  return new Intl.DateTimeFormat(window.navigator.language, options).format(
    new Date(value)
  );
}
