import { InputRightAddon as ChakraInputRightAddon } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { InputRightAddonProps } from './input-right-addon.types';

export default forwardRef(function Input(
  props: InputRightAddonProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraInputRightAddon {...props} ref={ref} />;
});
