import { Tr as ChakraTableRow } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TableRowProps } from './table-row.types';

export default forwardRef(function Tr(
  props: TableRowProps,
  ref: LegacyRef<HTMLTableRowElement>
) {
  return <ChakraTableRow {...props} ref={ref} />;
});
