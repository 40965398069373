import { Radio as ChakraRadio } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { RadioProps } from './radio.types';

export default forwardRef(function Radio(
  props: RadioProps,
  ref: LegacyRef<HTMLInputElement>
) {
  return <ChakraRadio {...props} ref={ref} />;
});
