import { Grid as ChakraGrid } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { GridProps } from './grid.types';

export default forwardRef(function Grid(
  props: GridProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraGrid {...props} ref={ref} />;
});
