import { ridersIntlIds } from './riders.ids';

export const ridersLangEnUs = {
  [ridersIntlIds.seo.pages.list.title]: 'Riders list',
  [ridersIntlIds.seo.pages.list.description]:
    'Web application for Rider management and last mile Deliveries',

  [ridersIntlIds.list.title]: `Riders list`,

  [ridersIntlIds.list.tab.all]: 'All',
  [ridersIntlIds.list.tab.active]: 'Active',
  [ridersIntlIds.list.tab.inactive]: 'Inactive',

  [ridersIntlIds.search.input.type]: 'Document',
  [ridersIntlIds.search.input.label]: 'Search for a Rider',
  [ridersIntlIds.search.input.placeholder]: 'Search',
  [ridersIntlIds.search.submitButton.label]: 'Search',

  [ridersIntlIds.table.header.id]: 'Rider ID',
  [ridersIntlIds.table.header.name]: 'Name',
  [ridersIntlIds.table.header.email]: 'E-mail',
  [ridersIntlIds.table.header.document]: 'Document',
  [ridersIntlIds.table.header.modality]: 'Modal',
  [ridersIntlIds.table.header.status]: 'Status',

  [ridersIntlIds.rider.modality.label]: `{modality, select,
    bicycle {Bicycle}
    motorcycle {Motorcycle}
    other {}
  }`,

  [ridersIntlIds.rider.status.active]: 'Active',
  [ridersIntlIds.rider.status.inactive]: 'Inactive',

  [ridersIntlIds.emptyState.label]: `{is_active, select,
    true {There are no Active Riders}
    false {There are no Inactive Riders}
    other {There are no Riders registered yet}
  }`,

  [ridersIntlIds.emptyState.search.label]:
    'Oops! We did not find any Rider with <highlight>Document {search_value}</highlight>',
};
