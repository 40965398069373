import { BreadcrumbLink as ChakraBreadcrumbLink } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { BreadcrumbLinkProps } from './breadcrumb-link.types';

export default forwardRef(function BreadcrumbLink(
  props: BreadcrumbLinkProps,
  ref: LegacyRef<HTMLAnchorElement>
) {
  return <ChakraBreadcrumbLink {...props} ref={ref} />;
});
