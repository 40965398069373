import { TabPanels as ChakraTabPanels } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TabPanelsProps } from './tab-panels.types';

export default forwardRef(function TabPanels(
  props: TabPanelsProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraTabPanels {...props} ref={ref} />;
});
