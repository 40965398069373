import { ridersIntlIds } from './riders.ids';

export const ridersLangPtBr = {
  [ridersIntlIds.seo.pages.list.title]: 'Lista de Riders',
  [ridersIntlIds.seo.pages.list.description]:
    'Aplicação web para gerenciamento de Riders e Entregas de última milha',

  [ridersIntlIds.list.title]: `Lista de Riders`,

  [ridersIntlIds.list.tab.all]: 'Todos',
  [ridersIntlIds.list.tab.active]: 'Ativos',
  [ridersIntlIds.list.tab.inactive]: 'Inativos',

  [ridersIntlIds.search.input.type]: 'CPF',
  [ridersIntlIds.search.input.label]: 'Buscar uma pessoa Rider',
  [ridersIntlIds.search.input.placeholder]: 'Buscar',
  [ridersIntlIds.search.submitButton.label]: 'Buscar',

  [ridersIntlIds.table.header.id]: 'ID Rider',
  [ridersIntlIds.table.header.name]: 'Nome',
  [ridersIntlIds.table.header.email]: 'E-mail',
  [ridersIntlIds.table.header.document]: 'CPF',
  [ridersIntlIds.table.header.modality]: 'Modal',
  [ridersIntlIds.table.header.status]: 'Status',

  [ridersIntlIds.rider.modality.label]: `{modality, select,
    bicycle {Bicicleta}
    motorcycle {Moto}
    other {}
  }`,

  [ridersIntlIds.rider.status.active]: 'Ativo',
  [ridersIntlIds.rider.status.inactive]: 'Inativo',

  [ridersIntlIds.emptyState.label]: `{is_active, select,
    true {Não há Riders Ativos}
    false {Não há Riders Inativos}
    other {Ainda não há Riders cadastrados}
  }`,

  [ridersIntlIds.emptyState.search.label]:
    'Ops! Não encontramos nenhuma pessoa Rider com o <highlight>CPF {search_value}</highlight>',
};
