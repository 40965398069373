import { Tab as ChakraTab } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TabProps } from './tab.types';

export default forwardRef(function Tab(
  props: TabProps,
  ref: LegacyRef<HTMLButtonElement>
) {
  return <ChakraTab {...props} ref={ref} />;
});
