import { AvatarBadge as ChakraAvatarBadge } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { AvatarBadgeProps } from './avatar-badge.types';

export default forwardRef(function AvatarBadge(
  props: AvatarBadgeProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraAvatarBadge {...props} ref={ref} />;
});
