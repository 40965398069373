import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import { envs } from '@config';
import { createClient } from 'graphql-ws';

export const makeWsMiddleware = () =>
  new GraphQLWsLink(
    createClient({
      url: envs.graphqlWsUrl,
    })
  );
