import { InputLeftAddon as ChakraInputLeftAddon } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { InputLeftAddonProps } from './input-left-addon.types';

export default forwardRef(function Input(
  props: InputLeftAddonProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraInputLeftAddon {...props} ref={ref} />;
});
