import { fromPromise } from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import { hermesLogger } from '@common/observability';
import { AuthHandler } from '@common/uaa/utils/auth-handler';

import { isExpiredTokenError } from './utils';

let isRefreshing = false;

export const setIsRefreshing = (value: boolean) => {
  isRefreshing = value;
};

let myPromise: Promise<any> = Promise.resolve();

export const refreshTokenMiddleware = onError(
  ({ graphQLErrors, operation, forward }) => {
    if (graphQLErrors) {
      if (isExpiredTokenError(graphQLErrors) && !isRefreshing) {
        hermesLogger.info('[ExpiredTokenError]', { operation });
        setIsRefreshing(true);
        myPromise = AuthHandler.refreshTokens();
        myPromise.then(() => {
          const oldHeaders = operation.getContext().headers;

          operation.setContext({
            headers: {
              ...oldHeaders,
              authorization: AuthHandler.authorization,
            },
          });

          setIsRefreshing(false);
          return forward(operation);
        });
      }
      return fromPromise(myPromise).flatMap(() => forward(operation));
    }
  }
);
