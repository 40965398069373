import { InputLeftElement as ChakraInputLeftElement } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { InputLeftElementProps } from './input-left-element.types';

export default forwardRef(function Input(
  props: InputLeftElementProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraInputLeftElement {...props} ref={ref} />;
});
