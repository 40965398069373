/* eslint-disable @next/next/google-font-display, @next/next/no-document-import-in-page, @next/next/no-page-custom-font */
import { DEFAULT_LOCALE } from '@common/i18n';
import { ColorModeScript } from '@common/ui-components';
import { Head, Html, Main, NextScript } from 'next/document';

export default function BootstrapDocument() {
  return (
    <Html lang={DEFAULT_LOCALE} translate="no">
      <Head>
        <link
          rel="stylesheet"
          href="https://fonts.googleapis.com/css2?family=Material+Symbols+Outlined:opsz,wght,FILL,GRAD@48,400,0,0"
        />
      </Head>
      <body>
        <ColorModeScript />
        <Main />
        <NextScript />
      </body>
    </Html>
  );
}
