export const uaaIntlIds = {
  signIn: {
    welcomeMessage: 'uaaIntlIds.signIn.welcomeMessage',
    signInInstruction: 'uaaIntlIds.signIn.signInInstruction',
    button: {
      label: 'uaaIntlIds.signIn.button.label',
    },
    manRidingBikeAlt: 'uaaIntlIds.signIn.manRidingBikeAlt',
  },
};
