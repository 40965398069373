import { InputGroup as ChakraInputGroup } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { InputGroupProps } from './input-group.types';

export default forwardRef(function InputGroup(
  props: InputGroupProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraInputGroup {...props} ref={ref} />;
});
