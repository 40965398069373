import { DrawerContent as ChakraDrawerContent } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { DrawerContentProps } from './drawer-content.types';

export default forwardRef(function DrawerContent(
  props: DrawerContentProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraDrawerContent {...props} ref={ref} />;
});
