import { hubsIntlIds } from './hubs.ids';

export const hubsLangEsMx = {
  [hubsIntlIds.seo.pages.performanceList.title]: 'Lista de Hubs',

  [hubsIntlIds.seo.pages.performanceList.description]:
    'Aplicación web para la gestión de Riders y Entregas de última milla',

  [hubsIntlIds.performanceList.hub.title]: `{hubs_length, plural,
    =0 {Sin Hubs}
    one {Hub: {hub_alias}}
    other {Lista de <highlight>#</highlight> hubs}}`,

  [hubsIntlIds.hubStatus.all]: 'Todos',
  [hubsIntlIds.hubStatus.active]: 'Activos',
  [hubsIntlIds.hubStatus.inactive]: 'Inactivos',

  [hubsIntlIds.performanceTable.cellHeader.hub]: 'Hub',
  [hubsIntlIds.performanceTable.cellHeader.status]: 'Status',
  [hubsIntlIds.performanceTable.cellHeader.address]: 'Dirección',
  [hubsIntlIds.performanceTable.cellHeader.deliveriesInProgress]:
    'Entregas en Progresso',
  [hubsIntlIds.performanceTable.cellHeader.ridersConnected]:
    'Riders Conectados',

  [hubsIntlIds.noHubsFound.label]: `{hubs_status, select,
      true {No hay Hubs Activos}
      false {No hay Hubs Inactivos}
      other {Aún no hay Hubs}
    }`,

  [hubsIntlIds.performanceTable.cellBody.deliveriesInProgress.total.label]:
    '{total} Entregas',
  [hubsIntlIds.performanceTable.cellBody.deliveriesInProgress.totalAtHub.label]:
    '{total_at_hub} en el Hub',
  [hubsIntlIds.performanceTable.cellBody.deliveriesInProgress.totalInRoute
    .label]: '{total_in_route} en Camino',

  [hubsIntlIds.performanceTable.cellBody.ridersConnected.total.label]:
    '{total} Riders',
  [hubsIntlIds.performanceTable.cellBody.ridersConnected.totalAtHub.label]:
    '{total_at_hub} en el Hub',
  [hubsIntlIds.performanceTable.cellBody.ridersConnected.totalInRoute.label]:
    '{total_in_route} en Camino',

  [hubsIntlIds.details.deliveries.inProgress.label]:
    '<highlight>{total}</highlight> Entregas en Progreso',
  [hubsIntlIds.details.deliveries.seeAll.label]: 'Ver todas',
  [hubsIntlIds.details.deliveries.inProgress.status
    .label]: `<highlight>{total}</highlight> Entregas {status, select,
    at_hub {en el Hub}
    in_route {en Camino}
    other {}
  }`,
  [hubsIntlIds.details.deliveries.inProgress.status.tag.alt.label]:
    'Icono de una distancia entre dos puntos',
  [hubsIntlIds.details.deliveries.inProgress.status.onTime.label]:
    'Entregas on Time',
  [hubsIntlIds.details.deliveries.inProgress.status.alreadyLate.label]:
    'Entregas ya tarde',
  [hubsIntlIds.details.deliveries.inProgress.status.inScandal.label]:
    'Entregas en Scandal',

  [hubsIntlIds.details.journeys.connected.label]:
    '<highlight>{total}</highlight> Riders conectados',
  [hubsIntlIds.details.journeys.seeAll.label]: 'Ver todos',
  [hubsIntlIds.details.journeys.connected.atHub.label]:
    '<highlight>{total}</highlight> Riders en el Hub',
  [hubsIntlIds.details.journeys.connected.inRoute.label]:
    '<highlight>{total}</highlight> Riders en Camino',
  [hubsIntlIds.details.journeys.connected.status.tag.alt.label]:
    'Icono de un casco de moto',
  [hubsIntlIds.details.journeys.connected.status.available.label]:
    'Riders Disponibles',
  [hubsIntlIds.details.journeys.connected.status.assigned.label]:
    'Riders Asignados',
  [hubsIntlIds.details.journeys.connected.status.paused.label]:
    'Riders en Pausa',
  [hubsIntlIds.details.journeys.connected.status.inRoute.label]:
    'Riders en Camino',
  [hubsIntlIds.details.journeys.connected.status.headingHub.label]:
    'Riders a camino del Hub',
};
