import { errorsIntlIds } from './errors.ids';

export const errorsLangPtBr = {
  [errorsIntlIds.seo.pages.notFound.title]: 'Página não encontrada',
  [errorsIntlIds.seo.pages.notFound.description]:
    'Aplicação web para gerenciamento de Riders e Entregas de última milha',

  [errorsIntlIds.pageNotFound.title]:
    'Ops! Página não encontrada <span>:(</span>',
  [errorsIntlIds.pageNotFound.description]:
    'Não entre em pânico! Basta voltar.',
  [errorsIntlIds.pageNotFound.button.label]: 'Voltar',

  [errorsIntlIds.systemError.title]: 'Opa! Aconteceu um erro no sistema',
  [errorsIntlIds.systemError.description]:
    '<highlight>Atualize a página</highlight> ou acesse o Raio mais tarde, por favor.',
  [errorsIntlIds.systemError.items.signOut.label]: 'Sair do Raio',
  [errorsIntlIds.systemError.items.reload.label]: 'Atualizar a página',
};
