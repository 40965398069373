import { errorsIntlIds } from './errors.ids';

export const errorsLangEsMx = {
  [errorsIntlIds.seo.pages.notFound.title]: 'Página no encontrada',
  [errorsIntlIds.seo.pages.notFound.description]:
    'Aplicación web para la gestión de Riders y Entregas de última milla',

  [errorsIntlIds.pageNotFound.title]:
    'Ops! Página no encontrada <span>:(</span>',
  [errorsIntlIds.pageNotFound.description]:
    '¡No entrar en pánico! Solo regresa.',
  [errorsIntlIds.pageNotFound.button.label]: 'Regresar',

  [errorsIntlIds.systemError.title]: '¡Ups! Ocurrió un error en el sistema',
  [errorsIntlIds.systemError.description]:
    '<highlight>Actualice la página</highlight> o acceda a Raio más tarde.',
  [errorsIntlIds.systemError.items.signOut.label]: 'Salir del Raio',
  [errorsIntlIds.systemError.items.reload.label]: 'Actualizar página',
};
