import { onError } from '@apollo/client/link/error';
import { hermesLogger } from '@common/observability';

export const networkErrorsMiddleware = onError(({ networkError }) => {
  if (networkError) {
    const { cause, message, name, stack } = networkError;
    hermesLogger.error(`[Network error]: ${networkError}`, {
      cause,
      message,
      name,
      stack,
    });
  }
});
