export const dashboardLayoutIntlIds = {
  sidebar: {
    navigation: {
      items: {
        deliveries: {
          label:
            'dashboardLayoutIntlIds.sidebar.navigation.items.deliveries.label',
        },
        riders: {
          availability: {
            label:
              'dashboardLayoutIntlIds.sidebar.navigation.items.riders.availability.label',
          },
          management: {
            label:
              'dashboardLayoutIntlIds.sidebar.navigation.items.riders.management.label',
          },
        },
        trips: {
          label: 'dashboardLayoutIntlIds.sidebar.navigation.items.trips.label',
        },
        hubs: {
          label: 'dashboardLayoutIntlIds.sidebar.navigation.items.hubs.label',
        },
      },
    },

    profile: {
      userName: 'dashboardLayoutIntlIds.sidebar.profile.userName',
      items: {
        signOut: 'dashboardLayoutIntlIds.sidebar.profile.items.signOut',
      },
    },
  },
};
