import { BreadcrumbItem as ChakraBreadcrumbItem } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { BreadcrumbItemProps } from './breadcrumb-item.types';

export default forwardRef(function BreadcrumbItem(
  props: BreadcrumbItemProps,
  ref: LegacyRef<HTMLLIElement>
) {
  return <ChakraBreadcrumbItem {...props} ref={ref} />;
});
