import { Checkbox as ChakraCheckbox } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { CheckboxProps } from './checkbox.types';

export default forwardRef(function Checkbox(
  props: CheckboxProps,
  ref: LegacyRef<HTMLInputElement>
) {
  return <ChakraCheckbox {...props} ref={ref} />;
});
