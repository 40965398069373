import { DrawerOverlay as ChakraDrawerOverlay } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { DrawerOverlayProps } from './drawer-overlay.types';

export default forwardRef(function DrawerOverlay(
  props: DrawerOverlayProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraDrawerOverlay {...props} ref={ref} />;
});
