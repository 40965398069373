import { noop } from '@utils';
import { createContext } from 'react';

import { DEFAULT_LOCALE, DEFAULT_TIMEZONE } from './constants';
import { I18nContextInterface } from './context.type';

export const I18nContext = createContext<I18nContextInterface>({
  locale: DEFAULT_LOCALE,
  setLocale: noop,
  timeZone: DEFAULT_TIMEZONE,
  setTimeZone: noop,
});
