import { TabPanel as ChakraTabPanel } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TabPanelProps } from './tab-panel.types';

export default forwardRef(function TabPanel(
  props: TabPanelProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraTabPanel {...props} ref={ref} />;
});
