import { dashboardLayoutIntlIds } from './dashboard-layout';

export const dashboardLayoutLangEsMx = {
  [dashboardLayoutIntlIds.sidebar.profile.userName]: 'Desconocido',
  [dashboardLayoutIntlIds.sidebar.navigation.items.deliveries.label]:
    'Entregas: Lista',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.availability.label]:
    'Rider: Disponibilidad',
  [dashboardLayoutIntlIds.sidebar.navigation.items.riders.management.label]:
    'Rider: Lista',
  [dashboardLayoutIntlIds.sidebar.navigation.items.trips.label]: 'Viajes',
  [dashboardLayoutIntlIds.sidebar.navigation.items.hubs.label]: 'Hubs: Lista',
  [dashboardLayoutIntlIds.sidebar.profile.items.signOut]: 'Salír',
};
