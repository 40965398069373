import { ApolloLink, split } from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';

import { authMiddleware } from './auth.middleware';
import { googleAuthMiddleware } from './google-auth.middleware';
import { graphQLErrorsMiddleware } from './graphql-errors.middleware';
import { httpMiddleware } from './http.middleware';
import { loggerMiddleware } from './logger.middleware';
import { networkErrorsMiddleware } from './network-errors.middleware';
import { refreshTokenMiddleware } from './refresh-token.middleware';
import { makeWsMiddleware } from './web-socket.middleware';

const makeHttpMiddleware = () =>
  ApolloLink.from([
    loggerMiddleware,
    authMiddleware,
    googleAuthMiddleware,
    refreshTokenMiddleware,
    networkErrorsMiddleware,
    graphQLErrorsMiddleware,
    httpMiddleware,
  ]);

export const mainMiddleware =
  typeof global.window !== 'undefined'
    ? split(
        ({ query }) => {
          const definition = getMainDefinition(query);
          return (
            definition.kind === 'OperationDefinition' &&
            definition.operation === 'subscription'
          );
        },
        makeWsMiddleware(),
        makeHttpMiddleware()
      )
    : makeHttpMiddleware();
