import { Img as ChakraImage } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { ImageProps } from './image.types';

export default forwardRef(function Img(
  props: ImageProps,
  ref: LegacyRef<HTMLImageElement>
) {
  return <ChakraImage {...props} ref={ref} />;
});
