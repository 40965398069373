export default function canChangeRiderByDeliveryStatus(
  status: string | null
): boolean {
  if (!status) {
    return false;
  }

  return [
    'pre_processed',
    'processed',
    'picking',
    'ready',
    'on_the_way',
  ].includes(status);
}
