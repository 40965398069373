import { DrawerCloseButton as ChakraDrawerCloseButton } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { DrawerCloseButtonProps } from './drawer-close-button.types';

export default forwardRef(function DrawerCloseButton(
  props: DrawerCloseButtonProps,
  ref: LegacyRef<HTMLButtonElement>
) {
  return <ChakraDrawerCloseButton {...props} ref={ref} />;
});
