import type { Content, Logger } from './hermes.types';

export class Hermes implements Logger {
  private _logger: Logger;

  constructor(logger: Logger) {
    this._logger = logger;
  }

  info(title: string, content?: Content) {
    this._logger.info(title, content);
  }

  debug(title: string, content?: Content) {
    this._logger.debug(title, content);
  }

  warn(title: string, content?: Content) {
    this._logger.warn(title, content);
  }

  error(title: string, content?: Content) {
    this._logger.error(title, content);
  }
}
