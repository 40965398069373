export default function Raio({ color = '#1759FF' }) {
  return (
    <svg
      width="93"
      height="48"
      viewBox="0 0 93 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M51.6866 18.1033C51.7693 18.0737 51.8521 18.0441 51.9341 18.0145C52.2787 17.9033 52.619 17.9617 52.9572 17.9876C54.582 18.1117 56.2084 18.2255 57.8333 18.3501C57.9478 18.3589 58.0631 18.3615 58.1769 18.371C58.6491 18.4117 58.936 18.6864 58.8469 19.0361C58.7367 19.4686 58.6085 19.8994 58.4843 20.3307C58.2797 21.0415 58.0814 21.7531 57.8769 22.4644C57.5833 23.4871 57.2993 24.5114 57.0065 25.5346C56.9185 25.842 56.8319 26.1492 56.7455 26.457C56.6761 26.7041 56.8368 26.8871 57.206 26.9127C58.3627 26.9922 59.5159 27.0909 60.6712 27.1772C60.9748 27.1999 61.1748 27.298 61.2767 27.5045C61.3244 27.6009 61.3268 27.6899 61.2721 27.7872C61.1227 28.0557 60.914 28.3052 60.7311 28.5621C59.8506 29.7992 58.9594 31.0324 58.0739 32.2678C57.3216 33.3175 56.5652 34.3665 55.8128 35.4162C54.9662 36.5966 54.1139 37.7748 53.273 38.9574C53.1953 39.0673 53.1127 39.1756 53.0317 39.2843C52.8954 39.4688 52.6655 39.551 52.3982 39.5878C52.335 39.5963 52.2848 39.5811 52.2453 39.5486C52.1919 39.5055 52.1442 39.4589 52.0916 39.4157C52.0505 39.3496 52.0094 39.2834 51.9683 39.2173C51.8686 38.8165 52.018 38.4148 52.0776 38.0149C52.249 36.8573 52.4502 35.7013 52.6342 34.5445C52.7693 33.6927 52.9077 32.8417 53.0499 31.9903C53.0879 31.7635 53.1284 31.5371 53.1586 31.3101C53.1938 31.046 53.0461 30.9114 52.6844 30.8838C51.1932 30.7713 49.7036 30.6537 48.2133 30.5367C48.156 30.5323 48.0987 30.5279 48.0427 30.5211C47.6147 30.4691 47.4016 30.2334 47.5073 29.9189C47.7026 29.3351 47.9034 28.7524 48.1025 28.1687C48.558 26.8338 49.0127 25.4985 49.4681 24.1631C49.8911 22.9235 50.3119 21.6842 50.7382 20.4453C50.973 19.7621 51.2047 19.0781 51.4396 18.3955C51.4811 18.2764 51.5472 18.1778 51.6866 18.1033Z"
        fill={color}
      />
      <path
        d="M24.3079 17.4799L20.9104 23.1032C20.2395 22.6186 19.4396 22.2509 18.3128 22.2509C16.971 22.2509 15.8357 22.9027 15.2078 24.4066V35.8871H8V16.7864H14.7089V18.867C15.8787 17.0371 17.814 16.1013 19.947 16.1013C21.7103 16.1013 23.0091 16.5441 24.3079 17.4799Z"
        fill={color}
      />
      <path
        d="M45.4913 23.6704V35.6686H39.1607V34.0986C37.5364 35.6273 35.3062 36.3544 32.4439 36.3544C28.0362 36.3544 26.1572 34.2225 26.1572 30.314C26.1572 26.5295 28.3786 24.4719 33.9892 24.4719H38.2651C38.2212 22.6623 37.1062 21.7368 34.4106 21.7368C32.4439 21.7368 31.1181 22.4226 29.7483 23.4721L26.2801 18.9604C28.5015 17.1094 31.6712 16.1013 35.9121 16.1013C41.3821 16.1013 45.4913 17.7457 45.4913 23.6704ZM38.2564 29.6282V28.6201H34.8321C33.5502 28.6201 32.9882 29.025 32.9882 29.8678C32.9882 30.6363 33.4975 31.1156 34.5248 31.1156C36.2018 31.1156 37.6154 30.595 38.2564 29.6282Z"
        fill={color}
      />
      <path
        d="M51.5394 12.2222C52 9.4663 53.9301 8.23458 56.6526 8.61362C59.4195 8.99883 60.8151 10.6935 60.3544 13.4495C59.8937 16.2054 57.9947 17.5228 55.2278 17.1376C52.5127 16.7686 51.0787 14.9782 51.5394 12.2222Z"
        fill={color}
      />
      <path
        d="M63.1442 27.8425V24.6132C63.1442 18.1962 68.1955 16.1013 74.0496 16.1013C79.9397 16.1013 85 18.1962 85 24.6132V27.8425C85 34.301 79.9488 36.3544 74.0496 36.3544C68.1955 36.3544 63.1442 34.301 63.1442 27.8425ZM77.4321 27.6024V24.8616C77.4321 22.5598 75.8897 21.8725 74.0496 21.8725C72.2094 21.8725 70.7121 22.5598 70.7121 24.8616V27.6024C70.7121 29.9042 72.2094 30.6329 74.0496 30.6329C75.8897 30.6329 77.4321 29.896 77.4321 27.6024Z"
        fill={color}
      />
    </svg>
  );
}
