import { DrawerHeader as ChakraDrawerHeader } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { DrawerHeaderProps } from './drawer-header.types';

export default forwardRef(function DrawerHeader(
  props: DrawerHeaderProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraDrawerHeader {...props} ref={ref} />;
});
