import { PropsWithChildren } from 'react';

import { I18nProviderProps } from './provider.types';
import I18nProviderWrapper from './provider-wrapper';

export default function I18nProvider({
  children,
  locale,
  timeZone,
}: PropsWithChildren<I18nProviderProps>) {
  return (
    <I18nProviderWrapper locale={locale} timeZone={timeZone}>
      {children}
    </I18nProviderWrapper>
  );
}
