import { ApolloProvider } from '@apollo/client';
import { PropsWithChildren, ReactElement } from 'react';

import { gqlClient } from '../client';
import { BffProviderProps } from './bff-provider.types';

export default function BffProvider({
  children,
  client = gqlClient,
}: PropsWithChildren<BffProviderProps<any>>): ReactElement {
  return <ApolloProvider client={client}>{children}</ApolloProvider>;
}
