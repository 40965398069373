import { Text } from '@common/ui-components';
import { ReactElement, ReactNode } from 'react';

export default function createHighlightTagWrapper(
  str: ReactNode
): ReactElement {
  return (
    <Text as="strong" color="blue.600">
      {str}
    </Text>
  );
}
