import { GridItem as ChakraGridItem } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { GridItemProps } from './grid-item.types';

export default forwardRef(function GridItem(
  props: GridItemProps,
  ref: LegacyRef<HTMLDivElement>
) {
  return <ChakraGridItem {...props} ref={ref} />;
});
