import { Button as ChakraButton } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { ButtonProps } from './button.types';

export default forwardRef(function Button(
  props: ButtonProps,
  ref: LegacyRef<HTMLButtonElement>
) {
  return <ChakraButton {...props} ref={ref} />;
});
