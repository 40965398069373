export const journeysIntlIds = {
  seo: {
    pages: {
      availabilityList: {
        title: 'journeysIntlIds.seo.pages.availabilityList.title',
        description: 'journeysIntlIds.seo.pages.availabilityList.description',
      },
    },
  },

  availabilityList: {
    hub: {
      title: 'journeysIntlIds.availabilityList.hub.title',
    },
  },

  journeyStatus: {
    all: 'journeysIntlIds.journeyStatus.all',
    pending: 'journeysIntlIds.journeyStatus.pending',
    available: 'journeysIntlIds.journeyStatus.available',
    inRoute: 'journeysIntlIds.journeyStatus.inRoute',
    paused: 'journeysIntlIds.journeyStatus.paused',
    canceled: 'journeysIntlIds.journeyStatus.canceled',
    offline: 'journeysIntlIds.journeyStatus.offline',
  },

  availabilityTable: {
    cellHeader: {
      hub: 'journeysIntlIds.availabilityTable.cellHeader.hub',
      riderId: 'journeysIntlIds.availabilityTable.cellHeader.riderId',
      riderName: 'journeysIntlIds.availabilityTable.cellHeader.riderName',
      journeyStatus:
        'journeysIntlIds.availabilityTable.cellHeader.journeyStatus',
      createdAt: 'journeysIntlIds.availabilityTable.cellHeader.createdAt',
      lastUpdate: 'journeysIntlIds.availabilityTable.cellHeader.lastUpdate',
      riderToken: 'journeysIntlIds.availabilityTable.cellHeader.riderToken',
    },

    cellBody: {
      journeyStatus: {
        endShift: {
          label:
            'journeysIntlIds.availabilityTable.cellBody.journeyStatus.endShift.label',
        },
      },
    },
  },

  forceRiderCheckOutModal: {
    title: 'journeysIntlIds.forceRiderCheckOutModal.title',
    description: 'journeysIntlIds.forceRiderCheckOutModal.description',
    buttons: {
      cancel: {
        label: 'journeysIntlIds.forceRiderCheckOutModal.buttons.cancel.label',
      },
      endShift: {
        label: 'journeysIntlIds.forceRiderCheckOutModal.buttons.endShift.label',
      },
    },
    toast: {
      onSuccess: {
        title: {
          label:
            'journeysIntlIds.forceRiderCheckOutModal.toast.onSuccess.title.label',
        },
        description: {
          label:
            'journeysIntlIds.forceRiderCheckOutModal.toast.onSuccess.description.label',
        },
      },
      onError: {
        title: {
          label:
            'journeysIntlIds.forceRiderCheckOutModal.toast.onError.title.label',
        },
        description: {
          label:
            'journeysIntlIds.forceRiderCheckOutModal.toast.onError.description.label',
        },
      },
    },
  },

  noJourneysFound: {
    label: 'journeysIntlIds.noJourneysFound.label',
  },
};
