export default function filterAndCountArray(
  arr: Array<Record<string, any> | null>,
  key: string,
  value: any
): number {
  return arr.filter((obj) => {
    if (obj === null) return false;
    return obj[key] === value;
  }).length;
}
