import { MenuButton as ChakraMenuButton } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { MenuButtonProps } from './menu-button.types';

export default forwardRef(function MenuButton(
  props: MenuButtonProps,
  ref: LegacyRef<HTMLButtonElement>
) {
  return <ChakraMenuButton {...props} ref={ref} />;
});
