import { PropsWithChildren, ReactElement, useEffect, useState } from 'react';
import { IntlProvider } from 'react-intl';

import { DEFAULT_LOCALE, I18nContext } from '../context';
import { DEFAULT_TIMEZONE } from '../context/constants';
import EnUs from '../messages/en-us';
import { I18nProviderProps } from './provider.types';

export default function I18nProviderWrapper({
  children,
  locale = DEFAULT_LOCALE,
  timeZone = DEFAULT_TIMEZONE,
}: PropsWithChildren<I18nProviderProps>): ReactElement {
  const [stateLocale, setStateLocale] = useState(locale);
  const [stateTimeZone, setStateTimeZone] = useState(timeZone);
  const [stateMessages, setStateMessages] = useState(EnUs);

  useEffect(
    function setStateLocale() {
      (async () => {
        if (stateLocale?.startsWith('pt')) {
          setStateMessages((await import('../messages/pt-br')).default);
        } else if (stateLocale?.startsWith('es')) {
          setStateMessages((await import('../messages/es-mx')).default);
        } else {
          setStateMessages((await import('../messages/en-us')).default);
        }
      })();
    },
    [stateLocale]
  );

  useEffect(
    function updateDocumentElementLang() {
      global.document.documentElement.lang = stateLocale;
    },
    [stateLocale]
  );

  return (
    <I18nContext.Provider
      value={{
        locale: stateLocale,
        setLocale: setStateLocale,
        timeZone: stateTimeZone,
        setTimeZone: setStateTimeZone,
      }}
    >
      <IntlProvider
        messages={stateMessages}
        locale={stateLocale}
        defaultLocale={DEFAULT_LOCALE}
        timeZone={stateTimeZone}
      >
        {children}
      </IntlProvider>
    </I18nContext.Provider>
  );
}
