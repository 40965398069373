import { Th as ChakraTableCellHead } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TableCellProps } from './table-cell-head.types';

export default forwardRef(function Th(
  props: TableCellProps,
  ref: LegacyRef<HTMLTableCellElement>
) {
  return <ChakraTableCellHead {...props} ref={ref} />;
});
