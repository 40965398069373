import { ridersIntlIds } from './riders.ids';

export const ridersLangEsMx = {
  [ridersIntlIds.seo.pages.list.title]: 'Lista de Riders',
  [ridersIntlIds.seo.pages.list.description]:
    'Aplicación web para la gestión de Riders y Entregas de última milla',

  [ridersIntlIds.list.title]: `Lista de Riders`,

  [ridersIntlIds.list.tab.all]: 'Todos',
  [ridersIntlIds.list.tab.active]: 'Activos',
  [ridersIntlIds.list.tab.inactive]: 'Inactivos',

  [ridersIntlIds.search.input.type]: 'Documento',
  [ridersIntlIds.search.input.label]: 'Buscar una persona Rider',
  [ridersIntlIds.search.input.placeholder]: 'Buscar',
  [ridersIntlIds.search.submitButton.label]: 'Buscar',

  [ridersIntlIds.table.header.id]: 'ID Rider',
  [ridersIntlIds.table.header.name]: 'Nombre',
  [ridersIntlIds.table.header.email]: 'E-mail',
  [ridersIntlIds.table.header.document]: 'Documento',
  [ridersIntlIds.table.header.modality]: 'Modal',
  [ridersIntlIds.table.header.status]: 'Status',

  [ridersIntlIds.rider.modality.label]: `{modality, select,
    bicycle {Bicicleta}
    motorcycle {Moto}
    other {}
  }`,

  [ridersIntlIds.rider.status.active]: 'Activo',
  [ridersIntlIds.rider.status.inactive]: 'Inactivo',

  [ridersIntlIds.emptyState.label]: `{is_active, select,
    true {No hay Riders Activos}
    false {No hay Riders Inactivos}
    other {Aún no hay Riders registrados}
  }`,

  [ridersIntlIds.emptyState.search.label]:
    '¡Ups! No encontramos ningún Rider con <highlight>Documento {search_value}</highlight>',
};
