import { ApolloLink } from '@apollo/client';

export const loggerMiddleware = new ApolloLink((operation, forward) => {
  // Request
  console.log({
    request: { operation, forward, context: operation.getContext() },
  });

  // Response
  return forward(operation).map((response) => {
    console.log({ response });
    return response;
  });
});
