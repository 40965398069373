import { Text } from '@common/ui-components';
import { ReactElement, ReactNode } from 'react';

export default function createSpanTagWrapper(str: ReactNode): ReactElement {
  return (
    <Text minW="fit-content" as="span">
      {str}
    </Text>
  );
}
