import { envs } from '@config';
import { datadogLogs } from '@datadog/browser-logs';

datadogLogs.init({
  clientToken: envs.ddClientToken,
  service: envs.ddService,
  site: envs.ddSite,
  env: envs.ddEnv,
  version: envs.ddVersion,
  forwardErrorsToLogs: true,
  sampleRate: 100,
});

export { datadogLogs };
