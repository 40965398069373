import { Td as ChakraTableCellData } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { TableCellProps } from './table-cell-data.types';

export default forwardRef(function Td(
  props: TableCellProps,
  ref: LegacyRef<HTMLTableCellElement>
) {
  return <ChakraTableCellData {...props} ref={ref} />;
});
