import { journeysIntlIds } from './journeys.ids';

export const journeysLangEsMx = {
  [journeysIntlIds.seo.pages.availabilityList.title]:
    'Lista de disponibilidad de Riders',

  [journeysIntlIds.seo.pages.availabilityList.description]:
    'Aplicación web para la gestión de Riders y Entregas de última milla',

  [journeysIntlIds.availabilityList.hub
    .title]: `Riders de: {hubs_length, plural,
    =0 {Sin hubs}
    one {{hub_alias}}
    other {<highlight>#</highlight> hubs}}`,

  [journeysIntlIds.journeyStatus.all]: 'Todos',
  [journeysIntlIds.journeyStatus.pending]: 'Pendiente',
  [journeysIntlIds.journeyStatus.available]: 'Disponible',
  [journeysIntlIds.journeyStatus.inRoute]: 'En Camino',
  [journeysIntlIds.journeyStatus.paused]: 'En Pausa',
  [journeysIntlIds.journeyStatus.canceled]: 'Cancelada',
  [journeysIntlIds.journeyStatus.offline]: 'Offline',

  [journeysIntlIds.availabilityTable.cellHeader.hub]: 'Hub',
  [journeysIntlIds.availabilityTable.cellHeader.riderId]: 'ID Rider',
  [journeysIntlIds.availabilityTable.cellHeader.riderName]: 'Nombre',
  [journeysIntlIds.availabilityTable.cellHeader.riderToken]: 'Código',
  [journeysIntlIds.availabilityTable.cellHeader.createdAt]: 'Creado a las',
  [journeysIntlIds.availabilityTable.cellHeader.journeyStatus]:
    'Status del Rider',
  [journeysIntlIds.availabilityTable.cellHeader.lastUpdate]:
    'Última actualización',

  [journeysIntlIds.availabilityTable.cellBody.journeyStatus.endShift.label]:
    'Terminar turno',

  [journeysIntlIds.forceRiderCheckOutModal.title]: 'Check-out: {rider_name}',
  [journeysIntlIds.forceRiderCheckOutModal.description]:
    '¿Deseas terminar el turno de {rider_name}?',
  [journeysIntlIds.forceRiderCheckOutModal.buttons.endShift.label]:
    'Terminar turno',
  [journeysIntlIds.forceRiderCheckOutModal.buttons.cancel.label]: 'Cancelar',
  [journeysIntlIds.forceRiderCheckOutModal.toast.onSuccess.title.label]:
    '{rider_name} checked-out',
  [journeysIntlIds.forceRiderCheckOutModal.toast.onSuccess.description.label]:
    'as las {formatted_time}',
  [journeysIntlIds.forceRiderCheckOutModal.toast.onError.title.label]:
    'Error al check-out {rider_name}',
  [journeysIntlIds.forceRiderCheckOutModal.toast.onError.description.label]:
    'Inténtalo de nuevo',

  [journeysIntlIds.noJourneysFound
    .label]: `No hay Riders {journey_status, select,
        pending {Pendientes}
        available {Disponibles}
        inRoute {En Camino}
        paused {En Pausa}
        canceled {Cancelados}
        offline {Offline}
        other {}
      }`,
};
