import { Select as ChakraSelect } from '@chakra-ui/react';
import { forwardRef, LegacyRef } from 'react';

import { SelectProps } from './select.types';

export default forwardRef(function Select(
  props: SelectProps,
  ref: LegacyRef<HTMLSelectElement>
) {
  return <ChakraSelect {...props} ref={ref} />;
});
