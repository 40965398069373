import { envs } from '@config';
import { datadogRum } from '@datadog/browser-rum';

datadogRum.init({
  applicationId: envs.ddApplicationId,
  clientToken: envs.ddClientToken,
  site: envs.ddSite,
  service: envs.ddService,
  env: envs.ddEnv,
  version: envs.ddVersion,
  sampleRate: 100,
  sessionReplaySampleRate: 20,
  trackInteractions: true,
  trackResources: true,
  trackLongTasks: true,
  trackFrustrations: true,
  defaultPrivacyLevel: 'mask-user-input',
});

export { datadogRum };
