import { hubsIntlIds } from './hubs.ids';

export const hubsLangPtBr = {
  [hubsIntlIds.seo.pages.performanceList.title]: 'Lista de Hubs',

  [hubsIntlIds.seo.pages.performanceList.description]:
    'Aplicação web para gerenciamento de Riders e Entregas de última milha',

  [hubsIntlIds.performanceList.hub.title]: `{hubs_length, plural,
    =0 {Sem Hubs}
    one {Hub: {hub_alias}}
    other {Lista de <highlight>#</highlight> hubs}}`,

  [hubsIntlIds.hubStatus.all]: 'Todos',
  [hubsIntlIds.hubStatus.active]: 'Ativos',
  [hubsIntlIds.hubStatus.inactive]: 'Inativos',

  [hubsIntlIds.performanceTable.cellHeader.hub]: 'Hub',
  [hubsIntlIds.performanceTable.cellHeader.status]: 'Status',
  [hubsIntlIds.performanceTable.cellHeader.address]: 'Endereço',
  [hubsIntlIds.performanceTable.cellHeader.deliveriesInProgress]:
    'Entregas em Andamento',
  [hubsIntlIds.performanceTable.cellHeader.ridersConnected]:
    'Riders Conectados',

  [hubsIntlIds.noHubsFound.label]: `{hubs_status, select,
      true {Não há Hubs Ativos}
      false {Não há Hubs Inativos}
      other {Ainda não há Hubs}
    }`,

  [hubsIntlIds.performanceTable.cellBody.deliveriesInProgress.total.label]:
    '{total} Entregas',
  [hubsIntlIds.performanceTable.cellBody.deliveriesInProgress.totalAtHub.label]:
    '{total_at_hub} no Hub',
  [hubsIntlIds.performanceTable.cellBody.deliveriesInProgress.totalInRoute
    .label]: '{total_in_route} em Rota',

  [hubsIntlIds.performanceTable.cellBody.ridersConnected.total.label]:
    '{total} Riders',
  [hubsIntlIds.performanceTable.cellBody.ridersConnected.totalAtHub.label]:
    '{total_at_hub} no Hub',
  [hubsIntlIds.performanceTable.cellBody.ridersConnected.totalInRoute.label]:
    '{total_in_route} em Rota',

  [hubsIntlIds.details.deliveries.inProgress.label]:
    '<highlight>{total}</highlight> Entregas em Andamento',
  [hubsIntlIds.details.deliveries.seeAll.label]: 'Ver todas',
  [hubsIntlIds.details.deliveries.inProgress.status
    .label]: `<highlight>{total}</highlight> Entregas {status, select,
    at_hub {no Hub}
    in_route {em Rota}
    other {}
  }`,
  [hubsIntlIds.details.deliveries.inProgress.status.tag.alt.label]:
    'Ícone da distância entre dois pontos',
  [hubsIntlIds.details.deliveries.inProgress.status.onTime.label]:
    'Entregas on Time',
  [hubsIntlIds.details.deliveries.inProgress.status.alreadyLate.label]:
    'Entregas já atrasadas',
  [hubsIntlIds.details.deliveries.inProgress.status.inScandal.label]:
    'Entregas em Scandal',

  [hubsIntlIds.details.journeys.connected.label]:
    '<highlight>{total}</highlight> Riders conectados',
  [hubsIntlIds.details.journeys.seeAll.label]: 'Ver todos',
  [hubsIntlIds.details.journeys.connected.atHub.label]:
    '<highlight>{total}</highlight> Riders no Hub',
  [hubsIntlIds.details.journeys.connected.inRoute.label]:
    '<highlight>{total}</highlight> Riders em Rota',
  [hubsIntlIds.details.journeys.connected.status.tag.alt.label]:
    'Ícone de um capacete de motocicleta',
  [hubsIntlIds.details.journeys.connected.status.available.label]:
    'Riders Disponíveis',
  [hubsIntlIds.details.journeys.connected.status.assigned.label]:
    'Riders Atribuídos',
  [hubsIntlIds.details.journeys.connected.status.paused.label]:
    'Riders em Pausa',
  [hubsIntlIds.details.journeys.connected.status.inRoute.label]:
    'Riders em Rota',
  [hubsIntlIds.details.journeys.connected.status.headingHub.label]:
    'Riders voltando ao Hub',
};
