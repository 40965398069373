import { gqlClient } from '@common/bff';
import { rum } from '@common/observability';
import { AuthHandler } from '@common/uaa/utils/auth-handler';
import { googleLogout } from '@react-oauth/google';
import { useEffect } from 'react';

import { UseLogoutHookInput } from './use-logout.hook.types';

export default function useLogout(props?: UseLogoutHookInput) {
  useEffect(function onLoadLogout() {
    googleLogout();

    AuthHandler.clearTokens();

    (async function clearStore() {
      await gqlClient.clearStore();
    })();

    rum.clearUser();

    (async function clearStore() {
      await gqlClient.clearStore();
    })();

    props?.onSuccess?.();
  }, []);
}
