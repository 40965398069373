import { hermesLogger } from '@common/observability';
import { AuthHandler, AuthToken } from '@common/uaa/utils/auth-handler';
import { useGoogleLogin } from '@react-oauth/google';
import axios from 'axios';

import { envs } from '../../../../config/envs';
import { UseLoginHookInput, UseLoginHookOutput } from './use-login.hook.types';

export default function useLogin(
  props?: UseLoginHookInput
): UseLoginHookOutput {
  function onError(error: any) {
    hermesLogger.error('[@common/uaa/hooks] Error to login', error);
    AuthHandler.clearTokens();
    props?.onError?.();
  }

  function loginUser(code: string) {
    (async () => {
      try {
        const { data } = await axios.post<AuthToken>(
          `${envs.restApiUrl}/auth/login`,
          null,
          {
            headers: {
              'auth-code': code,
            },
          }
        );

        hermesLogger.info('[@common/uaa/hooks] Success to login', data);

        AuthHandler.setTokens(data.accessToken, data.refreshToken);

        props?.onSuccess?.();
      } catch (error) {
        onError(error);
      }
    })();
  }

  const googleLogin = useGoogleLogin({
    ...props,
    flow: 'auth-code',
    onSuccess: (response) => {
      hermesLogger.info(
        '[@common/uaa/hooks] Logged in with google successfully',
        response
      );
      loginUser(response.code);
    },

    onError,
  });

  return googleLogin;
}
