import { errorsIntlIds } from './errors.ids';

export const errorsLangEnUs = {
  [errorsIntlIds.seo.pages.notFound.title]: 'Page not found',
  [errorsIntlIds.seo.pages.notFound.description]:
    'Web application for rider management and last mile deliveries',

  [errorsIntlIds.pageNotFound.title]: 'Ops! Page not found <span>:(</span>',
  [errorsIntlIds.pageNotFound.description]: "Don't panic! Just go back.",
  [errorsIntlIds.pageNotFound.button.label]: 'Go back',

  [errorsIntlIds.systemError.title]: 'Oops! An error occurred in the system',
  [errorsIntlIds.systemError.description]:
    '<highlight>Reload the page</highlight> or please access Raio again later.',
  [errorsIntlIds.systemError.items.signOut.label]: 'Leave Raio',
  [errorsIntlIds.systemError.items.reload.label]: 'Reload page',
};
