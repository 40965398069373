export default function formatTimer(diff: number) {
  const timeInSeconds = diff / 1000;

  const hours = Math.floor((timeInSeconds % (24 * 60 * 60)) / (60 * 60));
  const minutes = Math.floor((timeInSeconds % (60 * 60)) / 60);
  const seconds = Math.floor(timeInSeconds % 60);

  return `${(hours * 60 + minutes).toString().padStart(2, '0')}:${seconds
    .toString()
    .padStart(2, '0')}`;
}
